import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { AffiliateReport, AffiliateQueryParams } from 'src/app/v2/models/affiliate-report.model';
import { CloudFunction } from 'src/app/v2/models/cloud-function-reference.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private functions: AngularFireFunctions) { }

  public getAffiliateData({ companyID, startDate, endDate }: AffiliateQueryParams): Observable<AffiliateReport[]> {
    const getAffiliate = this.functions.httpsCallable(CloudFunction.getAffiliatesWithRentals);
    return getAffiliate({ companyID, startDate, endDate });
  }
}
