/* Libraries */
import { DateTime } from "luxon";

/* Models */
import { TimeslotOption } from "./booking-flow.model";

export interface DateRangeConfig {
    showAdditionalInputs: { showTimeslots: boolean }
    showFormLabels: boolean,
    showAvailabilityOverrideToggle: boolean,
    runErrorValidationOnInit: boolean,
    filterByCurrentDay: boolean,
    openDateRangeOnInit: boolean
};

export interface DateRangeInputOnChange {
    changedInput: DateRangeInputType,
    componentInputState: DateRangeInputState,
    isFormValid: boolean
}

export enum DateRangeInputType {
    DatePicker = 'datePicker',
    Location = 'location',
    Timeslot = 'timeslot',
    AvailabilityOverride = 'availabilityOverride',
    Manual = 'manual' // Triggered without an input change
}

export interface DateRangeInputState {
    selectedEndDate: DateTime,
    selectedStartDate: DateTime,
    selectedTimeslot: TimeslotOption
    selectedLocationID: string
}