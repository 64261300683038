import { Component, EventEmitter, Output } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { DateRange, PeriodType } from 'src/app/v2/models/affiliate-report.model';

@Component({
  selector: 'app-period-selection-daypicker',
  templateUrl: './period-selection-daypicker.component.html',
  styleUrls: ['./period-selection-daypicker.component.css']
})
export class PeriodSelectionDaypickerComponent {
  protected selectedRange: DateRange = { start: null, end: null };
  protected selectedPeriod: PeriodType = PeriodType.QuarterToDate;

  protected periodOptions = [
    { label: 'Quarter to Date', value: PeriodType.QuarterToDate },
    { label: 'Last Quarter', value: PeriodType.LastQuarter },
    { label: 'Custom range', value: PeriodType.CustomRange }
  ];

  @Output() periodSelected = new EventEmitter<DateRange>();

  constructor() { }

  ngOnInit(): void {
    this.setPeriod(PeriodType.QuarterToDate);
  }

  protected setPeriod(period: PeriodType): void {
    this.selectedPeriod = period;
    if (period === PeriodType.CustomRange) {
      return;
    }
    switch (period) {
      case PeriodType.QuarterToDate:
        this.selectedRange.start = DateTime.now().startOf('quarter');
        this.selectedRange.end = DateTime.now();
        break;
      case PeriodType.LastQuarter: {
        const lastQuarter = DateTime.now().minus({ quarters: 1 });
        this.selectedRange.start = lastQuarter.startOf('quarter');
        this.selectedRange.end = lastQuarter.endOf('quarter');
        break;
      }
    }
    this.emitPeriod();
  }

  protected applySelection(startInput: HTMLInputElement, endInput: HTMLInputElement, picker: MatDateRangePicker<Date>): void {
    const parseDate = (dateStr: string) => DateTime.fromFormat(dateStr, 'M/d/yyyy').isValid
    ? DateTime.fromFormat(dateStr, 'M/d/yyyy')
    : null;
    this.selectedRange = {
      start: startInput.value ? parseDate(startInput.value) : null,
      end: endInput.value ? parseDate(endInput.value) : null
    };
    this.emitPeriod();
    picker.close();
  }

  private emitPeriod(): void {
    this.periodSelected.emit(this.selectedRange);
  }

}
