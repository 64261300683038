import { DateTime } from "luxon";

export enum PeriodType {
  LastQuarter = 'lastQuarter',
  QuarterToDate = 'qtd',
  CustomRange = 'custom'
}

export interface DateRange {
  start: DateTime | null;
  end: DateTime | null;
}

export interface AffiliateQueryParams {
  companyID: string;
  startDate: string;
  endDate: string;
}

export interface AffiliateReport {
  affiliateName: string;
  earnings: number;
  numberOfRentals: number;
  numberOfClicks: number;
  conversionRatio: number;
  totalBookingCosts: number;
}
