import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';

/* Libraries */
import { CalendarEvent } from 'angular-calendar';
import { CalendarOptions, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

/* Models */
import { Rental } from 'src/app/models/storage/rental.model';

/* Services */
import { CurrentUserService } from '../../services/current-user.service';
import { RentalService } from 'src/app/services/rental.service';
import { Subscription } from 'rxjs';

interface CustomCalendarEvent extends CalendarEvent {
  obj?: any;
  total?: number;
  backgroundColor?: string;
  borderColor?: string;
  display?: "block";
  description?: string;
  textColor?: string;
}

@Component({
  selector: 'app-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss'],
})

export class BookingCalendarComponent {
  constructor(
    private currentUser: CurrentUserService,
    private modal: NgbModal,
    private rentalService: RentalService,
  ) {}

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  private rentalSubscription!: Subscription;

  protected color: string;
  public backgroundColor: string;
  public modalData: {
    clickInfo: EventClickArg;
  };
  public bookings = [];
  public colors;
  public font;
  public usercolor;
  public isLoading = false;


  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek',
    },
    initialView: 'dayGridMonth',
    weekends: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: this.bookings,
    datesSet: (dateInfo) => this.onCalendarMonthChange(dateInfo),
    eventClick: this.openmodal.bind(this),
  };

  private onCalendarMonthChange(dateInfo: DatesSetArg): void {
    const startDate: Date = dateInfo.start;
    const endDate: Date = dateInfo.end;  
    this.getRentalDays(startDate, endDate);
  }

  private getRentalDays(startDate: Date, endDate: Date): void {
    this.isLoading = true; 

    if(this.rentalSubscription){
      this.rentalSubscription.unsubscribe();
    }

    this.rentalSubscription = this.rentalService
      .getRentalByDatesForBookingCalendar(startDate, endDate, this.currentUser.currentUser.companyId)
      .subscribe({
        next: (rentals) => {
          this.bookings = rentals.map((rental) => {
            this.color = DEFAULT_THEME.getColorVariant();
            const totalValue = this.calculateTotalValue(rental.products);
            return this.createEventObject(rental, totalValue, this.color);
          });
      
          this.calendarOptions.events = this.bookings;
          this.isLoading = false; // Hide loader
        },
        error: (error) => {
          console.error("Error fetching rentals:", error);
          this.isLoading = false; // Hide loader even if an error occurs
        }
      });
  }
  
  // Calculate total value of rental products
  private calculateTotalValue(products: any[]): number {
    return products?.reduce((total, product) => total + product.subtotal, 0) || 0;
  }
  

  // Creates a calendar event object from a rental.
  private createEventObject(rental: any, totalValue: number, color: string): CustomCalendarEvent {

    if (!rental?.dayStart?.seconds || !rental?.dayEnd?.seconds) {
      console.warn('Warning: Missing or invalid dayStart or dayEnd in rental:', rental);
    }
  
    if (!rental?.rentalNumber) {
      console.warn('Warning: Missing rentalNumber in rental:', rental);
    }
  
    if (!rental?.userInfo?.name || !rental?.userInfo?.lastName) {
      console.warn('Warning: Missing user name or last name in rental:', rental);
    }

    const startDate = new Date(rental?.dayStart?.seconds * 1000);
    const endDate = new Date(rental?.dayEnd?.seconds * 1000);
    const rentalNumber = rental?.rentalNumber || 'N/A';
    const userName = rental?.userInfo?.name || '';
    const userLastName = rental?.userInfo?.lastName || '';
    const displayTime = moment(rental?.dayStart?.seconds * 1000).format('h:mm a');
  
    const title =
      rental.rentalType === 'byHour'
        ? `${displayTime} - #${rentalNumber} - ${userName} ${userLastName}`
        : `All day - #${rentalNumber} - ${userName} ${userLastName}`;
  
    return {
      start: startDate,
      end: endDate,
      title,
      obj: rental,
      total: totalValue,
      backgroundColor: color,
      borderColor: color,
      display: 'block',
      description: `#${rentalNumber} - ${userName} ${userLastName}`,
      textColor: DEFAULT_THEME.fontColor,
    };
  }
  

  private openmodal(clickInfo: EventClickArg): void {
    this.modalData = { clickInfo };
    this.modal.open(this.modalContent, { size: 'lg' });

    // Assign colors
    this.colors = DEFAULT_THEME.value;
    this.font = DEFAULT_THEME.fontColor;
  }

  ngOnDestroy() {
    if (this.rentalSubscription) {
      this.rentalSubscription.unsubscribe();
    }
  }
}
