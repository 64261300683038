import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

import { AffiliateReport, DateRange } from 'src/app/v2/models/affiliate-report.model';
import { ReportsService } from 'src/app/v2/services/reports.service';
import { CurrentUserService } from './../../../services/current-user.service';

const CSV_HEADERS: string[] = ['Affiliate Name', 'Total Earnings', 'Total Booking Costs', 'Number of Rentals', 'Number of Clicks', 'Conversion Ratio'];

@Component({
  selector: 'app-affiliate-report',
  templateUrl: './affiliate-report.component.html',
  styleUrls: ['./affiliate-report.component.css']
})
export class AffiliateReportComponent {

  protected selectedRange: DateRange = { start: null, end: null };
  protected companyID = this._currentUser.currentUser.companyId;
  protected displayedColumns: string[] = ['affiliateName', 'totalEarnings', 'totalBookingCosts', 'numberOfRentals', 'numberOfClicks', 'conversionRatio'];
  protected dataSource = new MatTableDataSource<AffiliateReport>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  protected totalEarnings: number = 0;
  protected totalEarningsHeader: number = 0;
  protected totalRentals: number = 0;
  protected totalRentalsHeader: number = 0;

  constructor(private _reportsService: ReportsService, private _currentUser: CurrentUserService) { }

  ngOnInit(): void { }

  protected onPeriodSelected(range: DateRange): void {
    this.selectedRange = range;
    this.fetchAffiliateData();
  }

  protected fetchAffiliateData(): void {
    const { start, end } = this.selectedRange;
    if (start && end) {
      const startDate = start.toISODate();
      const endDate = end.toISODate();
      Swal.fire({
        title: 'Loading...',
        text: 'Fetching affiliate data, please wait.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this._reportsService.getAffiliateData({ companyID: this.companyID, startDate, endDate })
      .subscribe({
        next: (response) => {
          console.log('Data from BigQuery:', response);
          this.dataSource = new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
          this.calculateTotals(response);
          Swal.close();
          this.animateCounter();
        },
        error: (error) => {
          console.error('Error fetching affiliate data:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to load affiliate data. Please try again later.',
          });
        }
      });
    } else {
      console.error('Invalid date range');
    }
  }

  private calculateTotals(data: AffiliateReport[]): void {
    this.totalEarnings = data.reduce((sum, item) => sum + item.earnings, 0);
    this.totalRentals = data.reduce((sum, item) => sum + item.numberOfRentals, 0);
  }

  private animateCounter(): void {
      this.animateValue(0, this.totalEarnings, 2000, (value) => this.totalEarningsHeader = parseFloat(value.toFixed(2)));
      this.animateValue(0, this.totalRentals, 2000, (value) => this.totalRentalsHeader = Math.round(value));
  }

  private animateValue(start: number, end: number, duration: number, callback: (value: number) => void): void {
      let current = start;
      const step = (end - start) / (duration / 30);

      const timer = setInterval(() => {
          current += step;
          if (current >= end) {
              clearInterval(timer);
              current = end;
          }
          callback(current);
      }, 30);
  }

  protected downloadCSV(): void {
    const csvData = this.convertToCSV(this.dataSource.data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, 'affiliate_report.csv');
  }

  private convertToCSV(data: AffiliateReport[]): string {
    const rows = data.map(row => this.formatRow(row));
    return [CSV_HEADERS.join(','), ...rows].join('\n');
  }

  private formatRow(row: AffiliateReport): string {
    return [
      `"${row.affiliateName}"`,
      `"${row.earnings.toFixed(2)}"`,
      `"${row.totalBookingCosts.toFixed(2)}"`,
      `"${row.numberOfRentals}"`,
      `"${row.numberOfClicks}"`,
      `"${row.conversionRatio.toFixed(2)}%"`
    ].join(',');
  }

}
