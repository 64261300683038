import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import Stepper from 'bs-stepper';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import * as QRious from 'qrious';
import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';
import { firstValueFrom, lastValueFrom, Subscription, take } from 'rxjs';
import { rejectEmptyString } from 'src/app/utils/pipes/customValidators';
import { environment } from 'src/environments/environment';

/* Models */
import { DateRangeInputOnChange, DateRangeInputType, DateRangeConfig, DateRangeInputState } from 'src/app/v2/models/date-range.model';
import { ErrorCodes, ErrorHandlingObject } from 'src/app/v2/models/errors.model';
import { Product } from 'src/app/models/storage/product.model'
import { ProductGroup } from 'src/app/models/storage/product-group.model'
import { WidgetType } from 'src/app/models/widget.model';
import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { makeNewCart } from 'src/app/models/storage/cart.model';
import { AvailabilityInterface, CartQuantities, ReconstructedOverlappingTimeslots, SortedList, SwappableProducts } from 'src/app/models/availability.model';
import { Company } from 'src/app/models/storage/company.model';
import { DiscountCode } from 'src/app/v2/models/storage/discount-code.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { Rental, makeNewRental } from 'src/app/models/storage/rental.model';
import { Log } from 'src/app/models/storage/log.model';

/* Services */
import { ErrorService } from 'src/app/v2/services/errors.service';
import { BackendBookingsService } from 'src/app/services/backend-bookings.service';
import { PricingService } from 'src/app/services/pricing.service';
import { CartService } from 'src/app/services/cart.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { ProductLocationService } from 'src/app/services/product-location.service';
import { TimeService } from 'src/app/services/time.service';
import { ProductsService } from 'src/app/services/products.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { RentalService } from 'src/app/services/rental.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { UserService } from 'src/app/services/user.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { OrderProcessingService } from 'src/app/services/order-processing.service';
import { LogService } from 'src/app/services/log.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';

/* Components */
import { DateRangeComponent } from '../booking-suite/date-range/date-range.component';
import { ReceiptComponent } from '../receipt/receipt.component';
import { BookingProductsTable2Component } from '../booking-products-table2/booking-products-table2.component';
import { BookingNextStatusComponent } from '../booking-next-status/booking-next-status.component';
import { BookingsComponent as BookingsComponent } from '../bookings/bookings.component';

/* Util */
import { doObjectsHaveEqualValues } from 'src/app/v2/utility/utility';
import { Collection } from 'src/app/v2/models/collection-reference.model';



declare var Stripe;
interface Country {
  shortName: string;
  name: string;
}
@Component({
  selector: 'app-bookings-upload2',
  templateUrl: './bookings-upload2.component.html',
  styleUrls: ['./bookings-upload2.component.scss'],

})
export class BookingsUpload2Component implements OnInit, OnChanges {
  protected daypickerFormIsValid: boolean = false;
  protected dateRangeFormDirty: boolean = false;
  protected changingToPrevious: boolean;
  private previouslySelectedDatetimes: { dayStart: DateTime, dayEnd: DateTime };
  protected dateRangeConfig: DateRangeConfig = {
    showAdditionalInputs: {
      showTimeslots: false
    },
    filterByCurrentDay: false,
    showFormLabels: true,
    showAvailabilityOverrideToggle: true,
    runErrorValidationOnInit: false,
    openDateRangeOnInit: false
  }

  private defaultAvailabilityOverrideConfig: AvailabilityOverrides = {
    overrideUnavailableHours: false,
    overrideUnavailableDays: false,
    overrideDOTW: false,
    overrideSearchIncludesToday: true, // On by default via this view
    overridePastDatePrevention: true // On by default via this view
  }

  private toggledOnAvailabilityOverrideConfig: AvailabilityOverrides = {
    overrideUnavailableHours: true,
    overrideUnavailableDays: true,
    overrideDOTW: true,
    overrideSearchIncludesToday: true,
    overridePastDatePrevention: true
  } 

  bookingInfo: UntypedFormGroup;
  chargeForm: UntypedFormGroup;
  securityForm: UntypedFormGroup;
  refundForm: UntypedFormGroup;
  form: UntypedFormGroup;

  myForm: FormGroup = this.fb.group({
    shopComments: ['', []],
  });

  shopSaveButton: boolean = true;

  // @ViewChild('popover',)

  @ViewChild('myAlert', { static: false })
  myAlert: ElementRef;
  hideMyAlert: boolean = true;

  @ViewChild('chargesModal', { static: false })
  chargesModal: ElementRef;
  hideChargeModal: boolean = true;

  @ViewChild('securityModal', { static: false })
  securityModal: ElementRef;
  hideSecurityModal: boolean = true;

  @ViewChild('completeModal', { static: false })
  completeModal: ElementRef;
  hideCompleteModal: boolean = true;

  @ViewChild('calculatorModal', { static: false })
  calculatorModal: ElementRef;
  hideCalculatorModal: boolean = true;

  @ViewChild('cardModal', { static: false })
  cardModal: ElementRef;
  hideCardModal: boolean = true;

  @ViewChild(DateRangeComponent) dateRangeComponent: DateRangeComponent;


  chargeAmount: number = 0;
  toSave: number = 0;
  amount: number = 0;
  percent: number = 0;

  @ViewChild(DateRangeComponent) DateRange: DateRangeComponent;

  @ViewChild(BookingProductsTable2Component)
  bookingsproducttable: BookingProductsTable2Component;
  @ViewChild('myTextarea') myTextarea: ElementRef;

  @ViewChild(BookingNextStatusComponent)
  bookingNextStatus: BookingNextStatusComponent;
  @ViewChild(ReceiptComponent) receiptComponent: ReceiptComponent;

  @Input() locationMap: { [id: string]: ProductLocation } = {};
  @Input() defaultLocationObj: ProductLocation = {};
  @Input() locations: ProductLocation[] = [];
  @Input() defaultLocationTimezone: string | undefined;
  @Input() companyObj: Company;
  @Input() rental: Rental;
  @Input() templates: any[];
  @Input() selectedTemplate: string;
  @Input() isQuickBook?: boolean = false; // Essentially means that a rental is not created yet, the rental will not have a dayStart or dayEnd until it is added to the database
  @Input('open') bookingsIsOpen: boolean = false;
  @Input('loadingCards') loadingcards: boolean = false;
  @Input() rentalTotalPaid: number = 0;
  @Input() rentalTotalCompletePaid: number = 0;
  @Output() rentalCostUpdate = new EventEmitter<Rental>();
  @Output() replaceRental = new EventEmitter();
  @Output() isQuickBookChange = new EventEmitter();

  protected availabilityOverrideConfig: AvailabilityOverrides;
  protected discountCodeMap: { [key: string]: DiscountCode } = {};

  protected selectedLocationID: string; // used for new date picker
  protected dayStart: DateTime | null;
  protected dayEnd: DateTime | null;
  protected savingRentalChanges: boolean = false;
  private availability: AvailabilityInterface;
  rentalBeforeOverride: Rental;

  protected pendingAmount: number = 0;
  receiptData: { rental: Rental, rentalReady: {}, dataSort: {}, totalChargeAmount: number, companyID: string, locationMap: { [id: string]: ProductLocation } };

  constructor(
    private toastr: ToastrService,
    private _afs: AngularFirestore,
    private _rentalService: RentalService,
    private fb: UntypedFormBuilder,
    private _cd: ChangeDetectorRef,
    private _sharedData: SharedDataService,
    private _route: ActivatedRoute,
    private _logService: LogService,
    private afFun: AngularFireFunctions,
    private _currentCompany: UserService,
    private _currentUser: CurrentUserService,
    protected _orderService: OrderProcessingService,
    public productService: ProductsService,
    private bookingscomponent: BookingsComponent,
    private inventoryPageService: InventoryPageService,
    private productLocationService: ProductLocationService,
    private timeService: TimeService,
    private availabilityService: AvailabilityService,
    private pricingService: PricingService,
    private cartService: CartService,
    private currentUserService: CurrentUserService,
    private _paymentsService: PaymentsService,
    private backendBookingsService: BackendBookingsService,
    private errorService: ErrorService,
    private _firestoreService: FirestoreService
  ) {
    this.bookingInfo = new UntypedFormGroup({
      dayStart: new UntypedFormControl({ disabled: true }, [
        Validators.required,
      ]),
      dayEnd: new UntypedFormControl({ disabled: true }, [Validators.required]),
      FirstName: new UntypedFormControl("", [Validators.required, rejectEmptyString]),
      LastName: new UntypedFormControl("", [Validators.required, rejectEmptyString]),
      Email: new UntypedFormControl("", [Validators.email]),
      Phone: new UntypedFormControl("", [Validators.required]),
      Address: new UntypedFormControl("", []),
      Address2: new UntypedFormControl(),
      city: new UntypedFormControl("", []),
      state: new UntypedFormControl("", []),
      country: new UntypedFormControl("", []),
      zip: new UntypedFormControl("", []),
    });
    this.chargeForm = new UntypedFormGroup({
      description: new UntypedFormControl('', [Validators.required]),
      amount: new UntypedFormControl('', [
        Validators.required,
        Validators.min(1),
      ]),
    });
    this.securityForm = new UntypedFormGroup({
      amount: new UntypedFormControl('', [
        Validators.required,
        Validators.min(1),
      ]),
    });
    this.refundForm = new UntypedFormGroup({
      description: new UntypedFormControl('', [Validators.required]),
      amount: new UntypedFormControl('', [
        Validators.required,
        Validators.min(1),
      ]),
    });
    this.checkoutForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      country: ['US', [Validators.required]],
    });

    //Emitter Listen
    this._paymentsService.onCompletePayment.subscribe(data => {
      data.chargeResponse.type = 'complete';
      data.chargeResponse.rentalID = this.rental.id;
      data.chargeResponse.metadata.userID = this._currentUser.currentUser.id;
      data.chargeResponse.companyID = this.rental.companyID;
      data.chargeResponse.metadata.userName = `${this._currentUser.currentUser.firstName} ${this._currentUser.currentUser.lastName}`;
      this._rentalService.addStripeTransaction(data.chargeResponse);
      this.rental.amountPaid = 0;
      delete this.rental['amountPendingAbs'];
      if ((this.typecharge == 'completeAmount' || 'complete')) {
        this.rental.amountPending = this.pricingService.roundDecimals(this.pendingAmount,2);
      } else {
        this.rental.amountPending -= this.pricingService.roundDecimals(data.chargeamount,2);
      }
      this._rentalService.updateRental(this.rental, this.rental.id);
      this.resetChargeForm();
      this._logService.addRentalLog(this.rental.id, data.message);
      Swal.fire({
        title: 'The charge has been created',
        icon: 'success',
      });
      this.hideAddCharge();
      this.bookingscomponent.rental.amountPaid = this.pricingService.roundDecimals(this.rental.amountPaid,2);
      this.bookingscomponent.rental.amountPending = this.pricingService.roundDecimals(this.rental.amountPending,2);
    })
    this._paymentsService.onSecurityPayment.subscribe(data => {
      data.depositChargeResponse.type = 'deposit';
      data.depositChargeResponse.rentalID = this.rental.id;
      data.depositChargeResponse.companyID = this.rental.companyID;
      data.depositChargeResponse.metadata.userName = `${this._currentUser.currentUser.firstName} ${this._currentUser.currentUser.lastName}`;
      data.depositChargeResponse.metadata.userID = this._currentUser.currentUser.id;
      this._rentalService.addStripeTransaction(data.depositChargeResponse);
      this.rental.finalTotal = Number(this.rental.cost);
      this._rentalService.updateRental(this.rental, this.rental.id);
      this.resetSecurityForm();
      this.bookingscomponent.asyncTotals(this.rental);
      this._logService.addRentalLog(this.rental.id, data.message);
      Swal.fire({
        title: 'The deposit has been created',
        icon: 'success',
      });
      this.hideAddSecurity();
    })
    this._paymentsService.onNormalCharge.subscribe(data => {
      data.chargeResponse.type = 'charge';
      data.chargeResponse.rentalID = this.rental.id;
      data.chargeResponse.metadata.userName = `${this._currentUser.currentUser.firstName} ${this._currentUser.currentUser.lastName}`;
      data.chargeResponse.metadata.userID = this._currentUser.currentUser.id;
      data.chargeResponse.companyID = this.rental.companyID;
      this._rentalService.addStripeTransaction(data.chargeResponse);
      const amount = Number(data.chargeResponse.amount ? (data.chargeResponse.amount / 100) : 0);
      if (this.rental.chargesID === undefined) {
        this.rental.chargesID = [{ chargeID: data.chargeResponse.id, cost: amount, date: new Date() }];
      } else {
        this.rental.chargesID.push({ chargeID: data.chargeResponse.id, cost: amount, date: new Date() });
      }
      this.rental.finalTotal = Number(this.rental.cost);
      this._rentalService.updateRental(this.rental, this.rental.id);
      this.bookingscomponent.asyncTotals(this.rental);
      this.submittedcharge = false;
      this.disabledcharge = false;
      this.resetChargeForm();
      this._logService.addRentalLog(this.rental.id, data.message);
      Swal.fire({
        title: 'The charge has been created',
        icon: 'success',
      });
      this.hideAddCharge();
    })

  }
  get f() {
    return this.bookingInfo.controls;
  }
  get c() {
    return this.chargeForm.controls;
  }
  get s() {
    return this.securityForm.controls;
  }
  get r() {
    return this.refundForm.controls;
  }
  get co() {
    return this.checkoutForm.controls;
  }

  public isAvailabilityOverride: boolean = false;


  rentalCost: any;
  protected fullpayment: boolean = false;
  protected divrefund: boolean = false;
  protected totaltorefund: number;
  protected descriptionRefund: string;
  protected impossiblerefund: boolean = false;
  protected statusPrepared: boolean = false;
  protected statusCheckedOut: boolean = false;
  protected statusCheckedIn: boolean = false;
  protected isFull: boolean = false;
  protected checkoutForm: UntypedFormGroup;
  protected paymentsmethods: any;
  protected legendLoad: string = 'Getting all Payment Methods';
  // public disabledbutton: boolean = false;
  protected disabledcharge: boolean = false;
  protected disabledsecurity: boolean = false;
  protected disabledrefund: boolean = false;
  protected submitted: boolean = false;
  protected submittedcharge: boolean = false;
  protected submittedsecurity: boolean = false;
  protected submittedrefund: boolean = false;
  protected submittedpayment: boolean = false;
  protected paymentIntentID: string;
  protected waiversArray: any[];
  protected waiversSigned: number = 0;
  clientSecret: any;
  protected amountToPay: number = 0;
  protected amountToPAYwTaxes: number = 0;
  protected noteForm: string;
  protected productsNotPossible: string[] = [];
  protected subtotal: number = 0;
  protected totalfees = 0;
  protected companyTax: number = 0;
  protected companyTaxes: number = 0;
  protected fleetTaxes: number = 0;
  protected fleetTax: number = 0;
  protected total = 0;
  public multipleWaivers: boolean = false;
  public pdfco = [];
  public chargeamount: number = 0;
  public chargedescription: string = '';
  public totalwithtaxes: string = '';
  protected totalWithTaxesAmount: number = 0;
  public applytaxes: boolean = true;
  public amounttocharge: number = 0;
  public submittedmodal: boolean = false;
  public countryISO = CountryISO; // ISO country codes
  public searchCountryField = SearchCountryField; // Search field for country selection
  public phoneNumberFormat = PhoneNumberFormat; // Phone number format options
  public BookingUserData = {
    City: '',
    State: '',
    Zip: '',
    Country: '',
  };
  elementTest: any;
  countries: Country[];
  states: string[];
  cities: string[];
  address: string;
  address2: string;
  isCountrySelect: boolean = false;
  zipTitle: string = 'Zip';
  stateTitle: string = 'State';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.Mexico,
    CountryISO.Canada,
  ];

  public smartwaiversigned: boolean = false;
  public smartwaiverdata: boolean = false;
  countriesWStates = [
    'Australia',
    'Austria',
    'Brazil',
    'Germany',
    'India',
    'Malaysia',
    'Mexico',
    'Micronesia',
    'Myanmar',
    'New Zealand',
    'Nigeria',
    'Palau',
    'South Sudan',
    'United States',
  ];
  countriesWZipCode = ['United States', 'Philippines'];

  country = new UntypedFormControl({ value: 'US' }, [Validators.required]);
  state = new UntypedFormControl('');
  city = new UntypedFormControl({ value: null, disabled: true });
  addressF = new UntypedFormControl({ value: null, hidden: true }, [
    Validators.required,
  ]);
  public email = '';
  formControl: UntypedFormGroup;
  public dataPI: any = {
    stripeID: '',
    paymentID: '',
    isTesting: '',
  };
  classStep1: string = 'stepCurrent';
  classStep2: string = 'stepInactive';
  classStep3: string = 'stepInactive';
  public stepper: Stepper;
  public charges: any[] = [];
  currentTab = 'Check Out';
  logs: Log[] = [];
  paymentElement: any;
  isError: boolean = false;
  isLoading: boolean = true;
  isHidden: boolean = true;
  public stripeID: string = '';
  public stripePublicKey: string = '';
  private stripe = Stripe(environment.stripe.key, {
    stripeAccount: environment.stripe.defaultAccount,
  });

  protected editDateRange = false;
  protected psuedoCartObj = { items: [] }; // This is used for the availability call
  protected runningAvailabilityCheck: boolean = false;
  protected productsMap: { [key: string]: Product };
  private productGroupsMap: { [key: string]: ProductGroup };
  private pricingResults: any = {};
  protected backendBookingData: any = {};
  protected noAvailabilities = { onDay: false, onTime: false };
  protected newRentalData: any = {};
  protected bookingListModalOpen: boolean = false;
  private rentalBeingModified: boolean = false;

  receiptRental: any;
  receiptRentalReady: any;
  rentalTypeOptions: any = [];
  hoursArray = [];
  editBookingOpen: boolean = false;
  protected subs = new Subscription(); // group of subscriptions
  protected itemsInCartObj: boolean;
  protected bookingTimeSlotSelected;
  protected smartwaiveractive = false;
  protected typecharge = '';
  protected customer: any;
  protected customerCreated: boolean = false;
  protected totalChargeAmount = 0;
  protected dataSort;
  protected productLocation;
  protected isPdfReady = false;
  protected rentalID;
  protected domain = window.location.origin;
  protected selectedPayment: boolean = false;
  protected selectedMethod: any;
  protected selectedNew: boolean = false;
  protected pendingtotal: number = 0;
  protected peoplenumber: number = 0;
  protected totalonewithout: number = 0;
  protected totalonewith: number = 0;
  protected totalaftertax: string = '';


  ngOnInit(): void {
    this.stripe = Stripe(this._rentalService.stripePublicKey, {
      stripeAccount: this._rentalService.stripeID,
    });

    if (!this.rental) {
      throw new Error('Rental does not exist, a rental is required');
    }

    this.rental = JSON.parse(JSON.stringify(makeNewRental(this.rental))); // Makes sure the rental has all of the correct fields available
    
   this.determineAvailabilityOverrideToggleStatusOnRental();

    this.determineItemsInCartObjBool(this.rental);

    if (!this.isQuickBook) {
      this._paymentsService.getDataPI(this.rental);
      this.getRental(this.rental.id);

    }
    this.getDiscountCodeMap();
    this.getCompanyPaymentInformation();
    this.editBookingOpen = false;
  }

  getCompanyPaymentInformation() {
    this.companyTax = this.companyObj.companyTax;
    this.fleetTax = this.companyObj.fleetTax;
    this.dataPI = {
      stripeID: this.companyObj.isTesting ? this.companyObj.stripeIDTest : this.companyObj.stripeID,
      paymentID: this.rental.paymentID,
      isTesting: this._rentalService.isTesting,
    };
  }

  ngAfterViewInit() {
    if (this.isQuickBook) {
      this.displayEditBooking(this.rental, 'show');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bookingsIsOpen && !changes.bookingsIsOpen.currentValue) {
      this.newRentalData = {}; // clears newly selected data upon user closing the modal
      this.editDateRange = false; // triggers ngIf to deconstruct date picker so that onInit can be ran again (to set default values)
      this.resetNoAvailabilitiesBooleans();
      this.editBookingOpen = false; // reset bool for products tabel
      this.rentalBeingModified = false; // reset bool for edit booking
    }

  }

  determineItemsInCartObjBool(rental) {
    if (rental?.cartObj?.items && rental?.cartObj?.items.length > 0) {
      this.itemsInCartObj = true;
    }
    else {
      this.itemsInCartObj = false;
    }
  }

  validateForm(rental) {
    this.submitted = true;
    if (this.productLocation == null || this.productLocation == undefined) {
      console.warn(`validateForm(rental=${rental.id} is missing productLocation`)
    }

    if (this.bookingInfo.controls['FirstName'].valid && this.bookingInfo.controls['LastName'].valid && this.bookingInfo.controls['Phone'].valid && !rental?.dayStart && !rental?.dayEnd) {
      this.savingRentalChanges = true;

      if (Object.keys(this.newRentalData).length === 0) {
        this.savingRentalChanges = false;
        this.toastr.error('Please select a start and end time', 'Error');
        return;
      }
      else {
        this.quickBookingAddRentalFields();
      }
    }

    if (this.bookingInfo.valid && rental?.dayStart && rental?.dayEnd) {
      this.editBooking(rental);
    }
    return
  }

  validateCharges() {
    this.submittedcharge = true;
    if (this.chargeForm.valid) {
      this.disabledcharge = true;
      this.normalCharge();
    }
  }
  validateSecurity() {
    this.submittedsecurity = true;
    if (this.securityForm.valid) {
      this.disabledsecurity = true;
      this.securityCharge();
    }
  }
  validateRefund() {
    this.submittedrefund = true;
    if (this.refundForm.valid && !this.impossiblerefund) {
      this.disabledrefund = true;
      this.partialRefund();
    }
  }
  getLogs(id: string) {
    this._afs
      .collection('rentals')
      .doc(id)
      .collection('log')
      .ref.orderBy('timestamp', 'desc')
      .limit(50)
      .onSnapshot((data) => {
        let docs: Log[] = data.docs.map((item) => {
          let data = item.data();
          let log = new Log(data);
          return log;
        });
        this.logs = docs;
        this._cd.detectChanges();
      });
    // this._logService.getLogsWithCollection("rentals", id, true).then(data => {
    //   this.logs = data
    // })
  }
  setStates(event: any) {
    const country = event.target.value || 'US';
    this.state.reset();
    this.state.disable();
    this.checkoutForm.controls.state.setValue("");
    if (country) {
      const currentCountry = this.countries.find((c) => c.shortName === country);

      if (this.countriesWStates.includes(currentCountry.name)) {
        this.stateTitle = 'State';
      } else {
        this.stateTitle = 'Province';
      }
      if (this.countriesWZipCode.includes(currentCountry.name)) {
        this.zipTitle = 'Zip';
      } else {
        this.zipTitle = 'Postal Code';
      }

      this.states = this._rentalService.getStatesByCountry(country);
      let noStates = [
        'AA',
        'AE',
        'AP',
        'AS',
        'FM',
        'GU',
        'MH',
        'MP',
        'PR',
        'PW',
        'VI',
      ];
      //si this.states includes noStates, then delete noStates from this.states
      this.states = this.states.filter((state) => !noStates.includes(state));
      this.isCountrySelect = true;
      this.state.enable();
    }
  }

  displayAddCharge() {
    this._rentalService.companyID = this.rental.companyID;
    const accordionCharge = document.getElementById(
      'accordionCharge'
    ) as HTMLElement | null;
    const btnAddCharge = document.getElementById(
      'btnAddCharge'
    ) as HTMLButtonElement | null;
    const btnCancelCharge = document.getElementById(
      'btnCancelCharge'
    ) as HTMLButtonElement | null;
    accordionCharge.style.display = 'block';
    btnAddCharge.style.display = 'none';
    btnCancelCharge.style.display = 'block';
  }
  displayAddRefund() {
    this.resetRefundForm();
    this.totaltorefund = 0;
    this.descriptionRefund = '';
    this.divrefund = true;
    this.rentalCost = this.pricingService.roundDecimals(this.rental.cost,2);
    this._rentalService.companyID = this.rental.companyID;
  }
  hideAddRefund() {
    this.divrefund = false;
  }
  // updatednewtotal() {
  //   this.maxtotal = Number(
  //     (
  //       this.bookingscomponent.rentalTotalPaid - this.refundForm.value.amount
  //     ).toFixed(2)
  //   );
  //   this.maxtotal < 0
  //     ? (this.impossiblerefund = true)
  //     : (this.impossiblerefund = false);
  // }
  partialRefund() {
    if (!this.impossiblerefund) {
      Swal.fire({
        title: 'Are you sure you want to make a refund?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Cancel',
        cancelButtonText: 'Refund',
      }).then((result) => {
        if (!result.isConfirmed) {
          Swal.fire({
            title: 'Processing Refund',
            html: '<div style="height: 100px"><span>Please wait while processing refund...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
            allowOutsideClick: false,
            showConfirmButton: false,
          });
          let dataRefund = {
            isTesting: this._rentalService.isTesting,
            stripeID: this.dataPI.stripeID,
            paymentID: this.rental.paymentID,
            amount: this.pricingService.roundDecimals((this.refundForm.value.amount * 100),2),
            description: this.refundForm.value.description,
            companyID: this.rental.companyID,
            email: this.rental.userInfo.email,
            id: this.rental.id,
          };
          this.afFun
            .httpsCallable('refundPaymentAmount')(dataRefund)
            .subscribe((resultrefund) => {
              if (resultrefund.raw?.code == 'charge_already_refunded') {
                Swal.fire({
                  title: 'Booking Refunded',
                  text: 'The payment has already been refunded before',
                  icon: 'error',
                });
              } else {
                if (this.rental.refundsID == undefined) {
                  this.rental.refundsID = [
                    {
                      refundID: resultrefund.id,
                      amount: this.pricingService.roundDecimals((dataRefund.amount / 100),2),
                      date: new Date(),
                    },
                  ];
                } else {
                  this.rental.refundsID.push({
                    refundID: resultrefund.id,
                    amount: this.pricingService.roundDecimals((dataRefund.amount / 100),2),
                    date: new Date(),
                  });
                }
                //this.rental.cost = this.maxtotal;
                //this.rental.finalTotal = Number(this.rental.cost.toFixed(2));
                this._rentalService.updateRental(this.rental, this.rental.id);
                this.bookingscomponent.asyncTotals(this.rental);
                //this.bookingscomponent.rentalModal.cost = this.maxtotal;
                this.resetRefundForm();
                this._logService.addRentalLog(
                  this.rental.id,
                  'Refund created, amount: ' +
                  this.pricingService.roundDecimals((dataRefund.amount / 100),2)
                );
                Swal.fire({
                  title: 'Refund Successful',
                  icon: 'success',
                });
              }
            });
        } else {
          this.disabledrefund = false;
        }
      });
    }
  }
  resetChargeForm() {
    this.chargeForm.controls['description'].setValue('');
    this.chargeForm.controls['amount'].setValue('');
    this.disabledcharge = false;
    this.submittedcharge = false;
  }
  resetSecurityForm() {
    this.securityForm.controls['amount'].setValue('');
    this.disabledsecurity = false;
    this.submittedsecurity = false;
  }
  resetRefundForm() {
    this.refundForm.controls['description'].setValue('');
    this.refundForm.controls['amount'].setValue('');
    this.disabledrefund = false;
    this.submittedrefund = false;
  }
  hideAddCharge() {
    const btnAddCharge = document.getElementById(
      'btnAddCharge'
    ) as HTMLButtonElement | null;
    btnAddCharge.style.display = 'block';
    this.resetChargeForm();
  }
  displayAddSecurityDeposit() {
    this._rentalService.companyID = this.rental.companyID;
    const accordionCharge = document.getElementById(
      'accordionSecurity'
    ) as HTMLElement | null;
    const btnAddCharge = document.getElementById(
      'btnAddSP'
    ) as HTMLButtonElement | null;
    const btnCancelCharge = document.getElementById(
      'btnCancelSP'
    ) as HTMLButtonElement | null;
    accordionCharge.style.display = 'block';
    btnAddCharge.style.display = 'none';
    btnCancelCharge.style.display = 'block';
  }
  hideAddSecurity() {
    const accordionCharge = document.getElementById(
      'accordionSecurity'
    ) as HTMLElement | null;
    const btnAddCharge = document.getElementById(
      'btnAddSP'
    ) as HTMLButtonElement | null;
    const btnCancelCharge = document.getElementById(
      'btnCancelSP'
    ) as HTMLButtonElement | null;
    const element: HTMLElement = document.getElementById(
      'SecurityText'
    ) as HTMLElement;
    element.innerHTML = '';
    accordionCharge.style.display = 'none';
    btnAddCharge.style.display = 'block';
    btnCancelCharge.style.display = 'none';
    this.resetSecurityForm();
  }
  writeTextCharge() {
    const element: HTMLElement = document.getElementById(
      'ChargeText'
    ) as HTMLElement;
    const txtAmountCharge = this.chargeForm.value.amount;
    if (txtAmountCharge == null || txtAmountCharge == '') {
      element.innerHTML = '';
    } else {
      this.subtotal = Number(txtAmountCharge); //Amount w/o taxes
      this.companyTaxes = this.pricingService.roundDecimals((this.subtotal * (this.companyTax / 100)),2);
      this.fleetTaxes = this.pricingService.roundDecimals((this.subtotal * (this.fleetTax / 100)),2);
      this.total = this.subtotal + this.companyTaxes + this.fleetTaxes;
      element.innerHTML =
        'Total with Taxes & Fees $' + this.pricingService.roundDecimals(this.total,2);
    }
  }
  writeTextSecurity() {
    const element: HTMLElement = document.getElementById(
      'SecurityText'
    ) as HTMLElement;
    const txtAmountSecurity = this.securityForm.value.amount;
    if (txtAmountSecurity == null || txtAmountSecurity == '') {
      element.innerHTML = '';
    } else {
      this.subtotal = Number(txtAmountSecurity); //Amount w/o taxes
      this.companyTaxes = this.pricingService.roundDecimals((this.subtotal * (this.companyTax / 100)),2);
      this.fleetTaxes = this.pricingService.roundDecimals((this.subtotal * (this.fleetTax / 100)),2);
      this.total = this.subtotal + this.companyTaxes + this.fleetTaxes;
      element.innerHTML =
        'Total with Taxes & Fees $' + this.pricingService.roundDecimals(this.total,2)
    }
  }

  public initAddressForm(country: string) {
    country = country || 'US'

    this.countries = this.bookingscomponent.countries;
    this.states = this._rentalService.getStatesByCountry(country);

    let noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    //si this.states includes noStates, then delete noStates from this.states
    this.states = this.states.filter((state) => !noStates.includes(state));

    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      this.city.disable();
      if (state) {
        this.checkoutForm.get('state').setValue(state);
        this.cities = this._rentalService.getCitiesByState(
          this.country.value,
          state
        );
        this.city.enable();
      }
    });
  }
  cancelBooking(rental: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure you want to cancel this Booking?',
        html: 'Cancelling this booking will <b>NOT</b> refund the client. Refunds can be done from the <b>Transactions</b> page. <br><br> Products associated with this cancellation will be made public again.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        cancelButtonText: 'No',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          rental.isCancelled = true;
          rental.isCheckedIn = false;
          rental.isCheckedOut = false;
          rental.isComplete = false;
          this.rental.statusDate['isCancelled'] = new Date();
          this._rentalService.updateRental(rental, rental.id);
          this._logService.addRentalLog(
            rental.id,
            'Booking # ' + rental.rentalNumber + ' cancelled'
          );
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'The Booking has been cancelled',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Operation Deny',
            'The Booking has not been cancelled',
            'error'
          );
        }
      });
  }
  reactivateBooking(rental: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure you want to reactivate this Booking?',
        text: 'It will verify that the products are available, if not, you will have to add new ones',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reactivate it',
        cancelButtonText: 'No',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          rental.isCancelled = false;
          rental.isCheckedIn = false;
          rental.isCheckedOut = false;
          rental.isComplete = false;
          this.rental.statusDate['isReactivated'] = new Date();
          this.rental.statusDate['isCancelled'] = '';
          this._rentalService.updateRental(rental, rental.id);
          this._logService.addRentalLog(rental.id, 'Booking Reactivate');
          swalWithBootstrapButtons.fire(
            'Reactivated Successfully',
            'The Booking has been reactivated',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Operation Deny',
            'The Booking has not been cancelled',
            'error'
          );
        }
      });
  }

  async adjustCartItemTimesToFirestoreTimestamp(cartObj) {
    await Promise.all(cartObj.items.map(async (item) => {
      item.dayStart = await this.timeService.convertToFirestoreTimestamp(item.dayStart);
      item.dayEnd = await this.timeService.convertToFirestoreTimestamp(item.dayEnd);
      return item;
    }));
    return cartObj;
  }

  resetNoAvailabilitiesBooleans() {
    this.noAvailabilities.onDay = false;
    this.noAvailabilities.onTime = false;
  }

  /**
   * @description Primarily called whenever the date ranges input is called
   */
  protected async dateRangeInputChanged($event: DateRangeInputOnChange): Promise<void> {
    this.daypickerFormIsValid = $event.isFormValid; // Get form validity status (used for disabling buttons)

    if ($event.changedInput !== DateRangeInputType.Manual) { // On all updates other than manual, form is considered dirty
      this.dateRangeFormDirty = true;
    }

    // Prevents event loop for when dates are changed to previous date values (ignores re-showing timeslot modal, on cancel, etc)
    if ($event.changedInput === DateRangeInputType.Manual && this.changingToPrevious) {
      this.changingToPrevious = false;
    }

    if (!$event.isFormValid) { // Do not continue further processing if invalid
      return
    }

    const inputs: DateRangeInputState = $event.componentInputState;

    // If location input is changed, reset newRentalData
    if ($event.changedInput === DateRangeInputType.Location) {
      this.newRentalData = {};
    }

    // this.dayStart = inputs.selectedStartDate.startOf('day'); // there may be a reason for this, unsure
    this.dayStart = inputs.selectedStartDate;
    this.dayEnd = inputs.selectedEndDate;

    this.resetNoAvailabilitiesBooleans();

    // Adjust moment dates / variables that the rest of the component relies on. These will be changed again once the user changes a time
    this.bookingInfo.value.dayStart = moment(inputs.selectedStartDate.toJSDate());
    this.bookingInfo.value.dayEnd = moment(inputs.selectedEndDate.toJSDate());

    if ($event.changedInput === DateRangeInputType.DatePicker || $event.changedInput === DateRangeInputType.Location) {
      if (this.itemsInCartObj) { // If there are items in the cart, we need to check availability
        this.runningAvailabilityCheck = true; // Show availability checking msg
        await this.runAvailabilityAlgo(this.rental, this.rental.id, this.dayStart, this.dayEnd, { items: [] }); // Populating with an empty cart because we're wanting to calculate availabilities for the entire day (don't want to be bound by cartLock)
        const timeslotsPossible: boolean = await this.getQuickBookTimeslots(inputs.selectedLocationID); // Attempt to generate timeslots
        if (timeslotsPossible) { // After selecting the date picker and passing the above validation, generate timeslots
          if (!this.changingToPrevious) {
            await this.filterTimeslotsByAvailability();
            this.runningAvailabilityCheck = false;
          }
        }
      } else {
        const timeslotsPossible: boolean = await this.getQuickBookTimeslots(inputs.selectedLocationID); // Attempt to generate timeslots
        if (timeslotsPossible) {
          this.openBookingListModal();
        }
      }
    }
  }

  async getQuickBookTimeslots(locationID): Promise<boolean> {
    this.selectedLocationID = locationID;
    this.productLocation = this.locationMap[this.selectedLocationID];

    let info = await this.availabilityService.initSetup(this.locations, this.selectedLocationID, this.timeService.convertTimestampToLuxon(this.dayStart), this.timeService.convertTimestampToLuxon(this.dayEnd), { items: [] }, false, this.availabilityOverrideConfig);

    let { results24hr, resultsShopDay, resultsHourly } = await this.backendBookingsService.getManualTimeSlots(info);
    let allResults = [...resultsHourly, ...resultsShopDay, ...results24hr,];
    this.backendBookingData['timeslotArr'] = allResults;

    this.rentalTypeOptions = [
      { type: "All", isSelected: true, show: true },
      { type: "Hourly", isSelected: false, show: true },
      { type: "All Day", isSelected: false, show: true },
      { type: "24 Hour", isSelected: false, show: true }
    ]
    if (info.daySpan > 1) {
      this.rentalTypeOptions.forEach(option => {
        if (option.type === "Hourly") {
          option.show = false;
        }
      })
    }
    else {
      this.hoursArray = this.backendBookingsService.getHoursArray(info);
    }

    if (this.backendBookingData['timeslotArr'].length <= 0) { // Edgecase for when day is available but no timeslots are
      const errObj: ErrorHandlingObject = { isValid: false,
        errors: [this.errorService.getErrorByCode(ErrorCodes.NO_TIMESLOTS_AVAILABLE, `No available timeslots were found for the following date range: ${this.dayStart.toLocaleString(DateTime.DATE_SHORT)} - ${this.dayEnd.toLocaleString(DateTime.DATE_SHORT)}`)] };
      this.dateRangeComponent.setErrorMessage(errObj);
      this.dateRangeFormDirty = true;
      this.daypickerFormIsValid = false;
      this.setDatesToPreviouslySelectedDateTimes(true);
      return false
    }
    return true
  }

  openBookingListModal() {
    if (this.newRentalData && Object.keys(this.newRentalData).length > 0) {
      this.bookingTimeSlotSelected = this.newRentalData
    }
    else if (this?.rental && this?.rental?.dayStart && this?.rental?.dayEnd) {
      let luxDayStart = this.timeService.convertTimestampToLuxon(this.rental?.dayStart);
      let luxDayEnd = this.timeService.convertTimestampToLuxon(this.rental?.dayEnd);
      let type = this.backendBookingsService.getTimeslotTypeBasedOnRentalType(this.rental.rentalType, this.rental);
      this.bookingTimeSlotSelected = { dayStart: luxDayStart, dayEnd: luxDayEnd, type: type }
    }
    else {
      this.bookingTimeSlotSelected = null
    }

    $('#bookings-timeslot-list').modal('show')
    this.bookingListModalOpen = true;
  }

  private async runAvailabilityAlgo(rental: Rental, rentalId: string, dayStart, dayEnd, psuedoCartObj, onSubmitFormData?: boolean): Promise<void> {
    // Check current availability override status to see if it should be assigned on the updated rental

    this.resetNoAvailabilitiesBooleans();

    this.availabilityService.getCollectionData(rental.companyID);

    // Await necessary collection results
    let res = await lastValueFrom(this.availabilityService.getCollections.pipe((take(1))))
    this.productGroupsMap = res['productGroupsMap'];
    this.productsMap = JSON.parse(JSON.stringify(res['productsMap']));

    this.availability = await this.availabilityService.runAvailabilityAlgorithm(
      dayStart, // search start date
      dayEnd, // search end date
      rental.rentalLocationID, // search / selected location
      this.rental.companyID,
      res['locationsArray'],
      res['productsArray'],
      res['productGroupsMap'],
      res['inventoryPageMap'],
      res['widgetMap'],
      res['productsMap'],
      psuedoCartObj,
      rentalId, // rental to ignore
      this.rental['allProductsID'], // product Ids to ignore,
      undefined,
      this.availabilityOverrideConfig
    )

    // Await algo results
    console.debug(`BookingsUpload2Component.runAvailabilityAlgo()`, this.availability);
    this.runningAvailabilityCheck = false;
    return;
  }

  async filterTimeslotsByAvailability(){
    // Processing for BOOKING TIMESLOT LIST
      this.backendBookingData = {};
      const resultData: boolean = await this.filterAvailabilityResults();
      if (resultData) {
        this.openBookingListModal();
      }
      else {
        this.bookingListModalOpen = false;
        this.noAvailabilities.onDay = true;
      }
      this.runningAvailabilityCheck = false;
  }

  private async filterAvailabilityResults(): Promise<boolean> {
    const cartProductWidgetIDs: string[] = await this.inventoryPageService.getAllCurrentRentalIDs(this.rental.cartObj, false, false, true);
    const allOriginalRentalIDs: string[] = await this.inventoryPageService.getAllCurrentRentalIDs(this.rental.cartObj, true, true, true);
    const cartSortedList: SortedList = await this.availability.getUniqueCartItemsCount(this.rental.cartObj);
    let result;

    try {
      result = await this.availability.getOverlapTimeslotsForProductGroupSize(cartSortedList);

      if (!result.timesAreAvail) {
        throw new Error('Times are not available in getOverlapTimeslotsForProductGroupSize');
      }

      result = await this.availability.filterOverlappingTimeSlotForProducts(result.sortedList);
      
      if (!result.timesAreAvail) {
        throw new Error('Times are not available in filterOverlappingTimeSlotForProducts');
      }

      result = await this.availability.filterOverlappingTimeSlotForProductsWidgets(result.sortedList, this.productsMap, allOriginalRentalIDs, result.IDsUsedForSwap);

      if (!result.timesAreAvail) {
        throw new Error('Times are not available in filterOverlappingTimeSlotForProductsWidgets');
      }

      result = await this.availability.filterOverlappingTimeSlotForCartWidget(result.sortedList, this.productsMap, cartProductWidgetIDs, allOriginalRentalIDs, result.IDsUsedForSwap);

      if (!result.timesAreAvail) {
        throw new Error('Times are not available in filterOverlappingTimeSlotForCartWidget');
      }
    }
    catch (err) {
      console.error('No time slots available', err)
      return false
    }

    let { allSlots, allUniqueHours }: { allSlots: ReconstructedOverlappingTimeslots, allUniqueHours: number[] } = await this.availability.combineAllOverlappingTimeslotsAndRestructure(result.sortedList);

    if (Object.keys(allSlots).length === 0) {
      return false;
    }

    allSlots = await this.backendBookingsService.getSwappableItems(allSlots);

    let allSlotsArray = Object.keys(allSlots).map((key) => allSlots[key]);

    this.backendBookingData = { allHours: allUniqueHours, timeslotArr: allSlotsArray }
    return true
  }

  private checkIfRentalIDsAreAvailable(productIDArray: string[], productWidgetIDArray: string[], dayStart, dayEnd, swappableItems: SwappableProducts[]): boolean {
    if (swappableItems) {
      if (swappableItems.length > 0) {
        swappableItems.forEach(item => {
          if (productIDArray.includes(item['originalID'])) {
            let index = productIDArray.indexOf(item['originalID']);
            productIDArray[index] = item['newID'];
          }
          else {
            let index = productWidgetIDArray.indexOf(item['originalID']);
            productWidgetIDArray[index] = item['newID'];
          }
        })
      }
    }
    let passCheck = true;

    // Check that products are still availale
    productIDArray.forEach((id) => {
      const luxDayStart = this.timeService.convertTimestampToLuxon(dayStart);
      const luxDayEnd = this.timeService.convertTimestampToLuxon(dayEnd)
      if (!this.availability.checkProductAvailabilityByTime(this.productsMap[id]['productGroupID'], id, luxDayStart, luxDayEnd)) {
        passCheck = false;
      }
    })
    return passCheck
  }


  updateCartWithNewRentalData() {
    let newCartObj = JSON.parse(JSON.stringify(this.rental.cartObj));
    newCartObj['items'].forEach(async (item, ind) => {

      newCartObj['items'][ind].dayStart = this.newRentalData['dayStart'].setZone(this.locationMap[this.selectedLocationID]['timezone']).toJSDate();
      newCartObj['items'][ind].dayEnd = this.newRentalData['dayEnd'].setZone(this.locationMap[this.selectedLocationID]['timezone']).toJSDate();

      newCartObj['items'][ind].dayStartString = this.newRentalData['dayStart'].setZone(this.locationMap[this.selectedLocationID]['timezone']).toLocaleString(DateTime.DATETIME_FULL);
      newCartObj['items'][ind].dayEndString = this.newRentalData['dayEnd'].setZone(this.locationMap[this.selectedLocationID]['timezone']).toLocaleString(DateTime.DATETIME_FULL);
      newCartObj['items'][ind].dayStartISO = this.newRentalData['dayStart'].setZone(this.locationMap[this.selectedLocationID]['timezone']).toISO()
      newCartObj['items'][ind].dayEndISO = this.newRentalData['dayEnd'].setZone(this.locationMap[this.selectedLocationID]['timezone']).toISO(),

        // this.rental.cartObj['items'][ind].selectedHours
        newCartObj['items'][ind].daySpan = this.newRentalData['availDaySpan'];

      if (this.newRentalData.type == 'shopDay') {
        newCartObj['items'][ind].selectedHours = 'All Day Rental';
      }
      else if (this.newRentalData.type == '24hr') {
        newCartObj['items'][ind].selectedHours = '24 Hour Rental';
      }
      else {
        newCartObj['items'][ind].selectedHours = this.newRentalData['hourLength'];
      }

      let totalAddonPrice = await this.pricingService.calculateAddonPrice(item['widgetList'], this.newRentalData['availDaySpan'], newCartObj['items'][ind]['selectedHours'], this.productGroupsMap[item['parentId']], false);
      newCartObj['items'][ind].addonPrice = totalAddonPrice;

      if (this.newRentalData['swappableItems'].length > 0) {
        this.newRentalData['swappableItems'].forEach(item => {
          if (newCartObj['items'][ind].productId == item['originalID']) {
            newCartObj['items'][ind].productId = item['newID'];
          }

          // go through all widgets and replace necessary IDs
          newCartObj['items'][ind].widgetList.forEach((widget, index) => {
            if (widget.widgetType === WidgetType.product) {
              let element = widget.element;
              element.options.forEach((option, ind) => {
                if (option['productsCheckedOut'].includes(item['originalID'])) {
                  let foundInd = option['productsCheckedOut'].findIndex(id => id === item['originalID']);
                  newCartObj['items'][ind].widgetList[index].element.options[ind]['productsCheckedOut'][foundInd] = item['newID'];
                }
              })
            }
          })
        })
      }
    })

    // Swap cart widget list IDs if necessary
    if (this.newRentalData['swappableItems'].length > 0) {
      this.newRentalData['swappableItems'].forEach(item => {
        newCartObj['cartWidgetList'].forEach((widget, index) => {
          if (widget.widgetType === WidgetType.product) {
            let element = widget.element;
            element.options.forEach((option, ind) => {
              if (option['productsCheckedOut'].includes(item['originalID'])) {
                let foundInd = option['productsCheckedOut'].findIndex(id => id === item['originalID']);
                newCartObj['cartWidgetList'][index].element.options[ind]['productsCheckedOut'][foundInd] = item['newID'];
              }
            })
          }
        })
      })
    }

    return newCartObj
  }

  getTimeslotSelection($event) {
    this.newRentalData = $event;
    this.newRentalData['locationObj'] = this.locationMap[this.selectedLocationID];
    this.dayStart = $event['dayStart'];
    this.dayEnd = $event['dayEnd'];
    this.previouslySelectedDatetimes.dayStart = $event['dayStart'];
    this.previouslySelectedDatetimes.dayEnd = $event['dayEnd'];
    this.bookingTimeSlotSelected = null;
    this.closeBookingTimeslotList()
  }

  private setDatesToPreviouslySelectedDateTimes(skipRevalidation?: boolean): void {
    this._cd.detectChanges(); // prevents err
    this.changingToPrevious = true;
    this.dayStart = this.previouslySelectedDatetimes.dayStart;
    this.dayEnd = this.previouslySelectedDatetimes.dayEnd;

    if(skipRevalidation){
      return;
    }

    this.dateRangeComponent.provideParamsOnInputChange(DateRangeInputType.Manual); // Re-runs date range validation on day range update
  }

  protected closeBookingTimeslotList(): void {
    this.setDatesToPreviouslySelectedDateTimes();
    this.bookingListModalOpen = false;
    $('#bookings-timeslot-list').modal('hide')
  }

  //New Complete Payment Function
  protected async completePayment(company?, rental?) {
    //Could be reduntant if we are getting the company in other function
    //We should do this in another place
    this.productLocation = await this._rentalService.getDefaultLocation(this.companyObj.defaultLocation);
    this.rental = rental || this.rental;

    this._paymentsService.completePayment(company, rental);
  }

  //New Security Charge Funcion
  protected securityCharge() {
    this._paymentsService.securityCharge(this.totalfees, this.total, this.fleetTaxes);
  }
  //New normalCharge Function
  protected normalCharge() {
    this._paymentsService.normalCharge(this.chargeForm.value.description, this.totalfees, this.total, this.fleetTaxes, this.subtotal);
  }

  //Charge Popup
  addChargePopup() {
    this.submittedmodal = false;
    this.applytaxes = true;
    this.chargeamount = 0;
    this.chargedescription = '';
    this.totalwithtaxes = 'Total with Taxes and Fees:';
    this.totalWithTaxesAmount = 0;
    this.amounttocharge = 0;
    this.hideChargeModal = false;

    Swal.fire({
      title: 'Charge',
      allowOutsideClick: false,
      html: this.chargesModal.nativeElement,
      showCancelButton: true,
      confirmButtonText: 'Confirm Charge',
      allowEnterKey: false,
      allowEscapeKey: false,
      preConfirm: () => {
        this.submittedmodal = true;
        if (this.amounttocharge <= 0) {
          Swal.showValidationMessage(`You need to write an Amount`);
        }
        if (this.chargedescription === '') {
          Swal.showValidationMessage(`You need to write a Description`);
        }
        return null;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        let html = '';
        if (this.selectedMethod?.new) {
          html = ' will be charged to a new card, wait for the form to open.';
        } else {
          html =
            ' will be charged to the card with termination <b>' +
            this.selectedMethod.charges.data[0].payment_method_details.card.last4;
          Swal.fire({
            title: 'Charging Amount',
            html:
              'The amount of $' +
              this.amounttocharge +
              html +
              '</b> for the reason: ' +
              this.chargedescription,
            icon: 'info',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          Swal.showLoading();
        }


        this.fleetTaxes = this.pricingService.roundDecimals((this.chargeamount * (this.fleetTax / 100)),2);
        this.companyTaxes = this.pricingService.roundDecimals((this.chargeamount * (this.companyTax / 100)),2);
        if (this.selectedMethod?.new) {
          this.fullpayment = false;
          this.amountToPay = Number(Number(this.chargeamount));
          this.typecharge = 'charge';
          this.openPaymentModal();
        } else {
          try {
            const datatransfer = await this.createChargeForSelectedMethod(false);
            this._paymentsService.handleNormalChargeResponse(datatransfer, this.chargeamount);
          } catch (error) {
            this._paymentsService.handleError(error);
          }
        }
      }
    });
  }
  //Security Popup
  addSecurityPopup() {
    this.submittedmodal = false;
    this.applytaxes = true;
    this.chargeamount = 0;
    this.chargedescription = 'Security Deposit';
    this.totalwithtaxes = 'Total with Taxes and Fees:';
    this.totalWithTaxesAmount = 0;
    this.amounttocharge = 0;
    this.hideSecurityModal = false;

    Swal.fire({
      title: 'Security Deposit',
      allowOutsideClick: false,
      html: this.securityModal.nativeElement,
      showCancelButton: true,
      confirmButtonText: 'Confirm Security Deposit',
      allowEnterKey: false,
      allowEscapeKey: false,
      preConfirm: () => {
        this.submittedmodal = true;
        if (this.amounttocharge <= 0) {
          Swal.showValidationMessage(`You need to write an Amount`);
        }
        return null;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        let html = '';
        if (this.selectedMethod?.new) {
          html = ' will be charged to a new card, wait for the form to open.';
        } else {
          html =
            ' will be charged to the card with termination <b>' +
            this.selectedMethod.charges.data[0].payment_method_details.card.last4;
          Swal.fire({
            title: 'Charging Amount',
            html:
              'The amount of $' +
              this.amounttocharge +
              html +
              '</b> for the reason: ' +
              this.chargedescription,
            icon: 'info',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          Swal.showLoading();
        }

        console.log(this.chargeamount)
        console.log(this.fleetTax)
        console.log(this.companyTax)

        this.fleetTaxes = this.pricingService.roundDecimals((this.chargeamount * (this.fleetTax / 100)),2);
        this.companyTaxes = this.pricingService.roundDecimals((this.chargeamount * (this.companyTax / 100)),2);
        if (this.selectedMethod?.new) {
          this.fullpayment = false;
          console.log(this.chargeAmount)
          this.amountToPay = this.pricingService.roundDecimals(this.chargeamount,2);
          this.typecharge = 'deposit';
          this.openPaymentModal();
        } else {
          try {
            const datatransfer = await this.createChargeForSelectedMethod(true);
            this._paymentsService.handleDepositChargeResponse(datatransfer);
          } catch (error) {
            this._paymentsService.handleError(error);
          }
        }
      }
    });
  }
  private async createChargeForSelectedMethod(isDeposit?: boolean) {
    const data = {
      applyTaxes: this.applytaxes,
      title: this.chargedescription,
      customer: this.selectedMethod.customer,
      id: this.selectedMethod.id,
      cost: this.pricingService.roundDecimals(this.totalfees,2),
      amount: this.pricingService.roundDecimals(this.amounttocharge,2),
      companyTaxes: this.companyTaxes || 0,
      fleetTaxes: this.fleetTaxes || 0,
      fleetTax: (this.fleetTax / 100),
      companyTax: (this.companyTax / 100),
      fleetTaxNumber: this.applytaxes ? this.fleetTaxes : 0,
      totaltotransfer: Number(this.chargeamount),
      paymentMethod: this.selectedMethod.payment_method,
      destination: this.dataPI.stripeID,
      companyID: this.rental.companyID,
      amountDestination: Number(this.chargeamount),
      transactiondata: this.selectedMethod.metadata,
      isDeposit: isDeposit,
    };
    return await this.afFun.httpsCallable('createCharge')({
      data: data,
      isTesting: this._rentalService.isTesting,
      type: isDeposit ? 'deposit' : 'charge',
    }).toPromise();
  }

  private getDiscountObject(): any {
    if (this.rental.cartObj.promoApplied) {
      return this.discountCodeMap[this.rental.cartObj.promocode.toLowerCase()];
    }
    return null;
  }

  async calculateCartTotal() {
    try {
      this.availabilityService.getCollectionData(this.rental.companyID);
      const res = await firstValueFrom(this.availabilityService.getCollections.pipe(take(1)));
      
      this.productGroupsMap = res['productGroupsMap'];
      let discountObj = this.getDiscountObject();
    
      this.pricingResults = await this.pricingService.calculateCartTotal(
        this.rental.cartObj,
        this.productGroupsMap,
        discountObj
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }
  

  //Complete Payment Popup
  addCompletePopup(back?: boolean) {
    this.calculateCartTotal();
    this.submittedmodal = false;
    if(!back) {
      this.chargeamount = this.pricingService.roundDecimals(this.rental.amountPending,2);
    }
    this.chargedescription = 'Complete Payment';
    this.hideCompleteModal = false;
    this.applytaxes = false;
    this.changeAllPrice('complete');
    Swal.fire({
      title: 'Complete Payment',
      allowOutsideClick: false,
      html: this.completeModal.nativeElement,
      showCancelButton: true,
      confirmButtonText: 'Confirm Payment',
      allowEnterKey: false,
      allowEscapeKey: false,
      preConfirm: async () => {
        this.submittedmodal = true;
        if (Number(this.chargeamount) < 1) {
          Swal.showValidationMessage(`The amount must be at least $1`);
          return false;
        }

        if (this.pricingService.roundDecimals(this.chargeamount, 2) > this.pricingService.roundDecimals(this.rental.amountPending, 2)) {
          const result = await Swal.fire({
            title: 'Warning',
            text: 'The amount entered is greater than the pending amount. Are you sure you want to proceed?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Back',
            allowOutsideClick: false,
            allowEscapeKey: false
          });

          if (!result.isConfirmed) {
            this.addCompletePopup(true);
            return false; // Go back to the main modal
          }
        }
        return true;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        let html = '';
        if (this.selectedMethod?.new) {
          html = ' will be charged to a new card, wait for the form to open.';
        } else {
          html =
            ' will be charged to the card with termination <b>' +
            this.selectedMethod.charges.data[0].payment_method_details.card.last4;
          Swal.fire({
            title: 'Charging Amount',
            html:
              'The amount of $' +
              this.amounttocharge +
              html +
              '</b> for the reason: ' +
              this.chargedescription,
            icon: 'info',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          });
          Swal.showLoading();
        }

        const percentageToPay = (this.totalWithTaxesAmount / this.rental.cost);
        //const widgetTipBalance = this.pricingService.roundDecimals((this.pricingResults.tipWidgetTotal * percentageToPay),2);
        const fleetTaxPercentage = this.pricingService.roundDecimals((this.pricingResults.feeTotal * percentageToPay),2);
        const companyTaxPercentage = this.pricingService.roundDecimals((this.pricingResults.taxTotal * percentageToPay),2);
        this.fleetTaxes = fleetTaxPercentage;
        this.companyTaxes = companyTaxPercentage;
        if (this.selectedMethod?.new) {
          this.handleNewPaymentMethod();
        } else {
          this.handleExistingPaymentMethod();
        }
      }
    });
  }

  calculateTaxes(): void {
    const fleetTaxPercentage = this.fleetTax / 100;
    const companyTaxPercentage = this.companyTax / 100;
    const totalTaxPercentage = fleetTaxPercentage + companyTaxPercentage;

    const initialAmount = this.pricingService.roundDecimals((this.totalWithTaxesAmount / (1 + totalTaxPercentage)),2);
    this.fleetTaxes = this.pricingService.roundDecimals((initialAmount * fleetTaxPercentage),2);
    this.companyTaxes = this.pricingService.roundDecimals((initialAmount * companyTaxPercentage),2);
  }

  handleNewPaymentMethod(): void {
    this.fullpayment = false;
    this.amountToPay = Number(this.chargeamount);
    this.typecharge = (Number(this.chargeamount) !== this.rental.amountPending) ? 'completeAmount' : 'complete';
    this.openPaymentModal();
  }

  async handleExistingPaymentMethod(): Promise<void> {
    try {
      const datatransfer = await this.createChargeForSelectedMethod(false);
      this._paymentsService.handleChargeResponse(datatransfer, this.chargeamount);
    } catch (error) {
      this._paymentsService.handleError(error);
    }
  }


  async quickBookingAddRentalFields() {
    let newRental = JSON.parse(JSON.stringify(makeNewRental(this.rental)));
    // this.rental = makeNewRentalObject(this.rental);
    newRental.userInfo = this.setCustomerInfo();
    // Assign javascript date variants on the newRental for firestore compatibility
    newRental.dayStart = this.dayStart.toJSDate();
    newRental.dayEnd = this.dayEnd.toJSDate();
    newRental.companyID = this.companyObj.id;
    newRental.created = new Date();
    newRental.statusDate['isReserved'] = new Date();
    newRental.lastModified = new Date();
    // newRental.availabilityOverrideConfig = this.applyOverrideConfig();
    newRental.availabilityOverrideConfig = this.availabilityOverrideConfig;
    newRental.rentalLocationID = this.selectedLocationID;
    newRental.timeZone = this.locationMap[this.selectedLocationID]['timezone'];
    newRental.isConfirmed = true;

    newRental.cartObj = makeNewCart();
    newRental.cartObj.dateCreated = new Date();
    newRental.cartObj.companyId = this.companyObj.id;
    newRental.cartObj.customerInfo = newRental.userInfo;
    newRental.timeslotType = this.newRentalData['type'] || "";
    newRental.cartObj.isSkipPayment = true;

    if (this.newRentalData['type'] == 'shopDay' || this.newRentalData['type'] == '24hr') {
      newRental.rentalType = 'byDay';
    }
    else {
      newRental.rentalType = 'byHour';
    }

    let psuedoCartObj = { items: [{ dayStart: this.newRentalData.dayStart, dayEnd: this.newRentalData.dayEnd, timeslotType: newRental.timeslotType || '' }] };
    await this.runAvailabilityAlgo(newRental, '', this.newRentalData.dayStart, this.newRentalData.dayEnd, psuedoCartObj, true);
    const availList: CartQuantities[] = this.availability.availCartQuantitiesList();

    if (availList.length === 0) {
      this.noAvailabilities.onTime = true;
      this.savingRentalChanges = false; // hide spinner
      return;
    }

    this.createQuickBookRental(newRental);

  }

  async createQuickBookRental(newRental) {
    try {
      await this._rentalService.addRental(newRental).then(async (data) => {
        newRental.id = data.id;
        await this._rentalService.updateRentalAndReturnRental({ id: newRental.id }, newRental.id).then(async (result: Rental) => {
          this.rental = result;
          await this._logService.addRentalLog(this.rental.id, 'Rental created');
          await this._logService.addRentalLog(this.rental.id, 'Rental without payment');

          if (this.isAvailabilityOverride) { // If the override is enabled add the config to the message
            let message = "Availability override: disabled -> enabled";
            let enabledOverrides = "Bypassing the following availability checks: ";
            Object.keys(this.rental.availabilityOverrideConfig).forEach((key, index) => {
              switch (key) {
                case 'overrideUnavailableHours':
                  enabledOverrides += "Unavailable Hours";
                  break;
                case 'overrideUnavailableDays':
                  enabledOverrides += "Unavailable Days";
                  break;
                case 'overrideSearchIncludesToday':
                  enabledOverrides += "Current search day time adjustments";
                  break;
                case 'overrideDOTW':
                  enabledOverrides += "Day of the Week restrictions";
                  break;
              }

              if (index < Object.keys(this.rental.availabilityOverrideConfig).length - 1) { // Separate with commas if not last item
                enabledOverrides += ", ";
              }
            })
            await this._logService.addRentalLog(this.rental.id, message);
            await this._logService.addRentalLog(this.rental.id, enabledOverrides);
          }
        })
      });
    }
    catch (err) {
      Swal.fire({
        title: 'Processing Error',
        html: `This operation failed to complete. Please try again, or if the issue persists, reach out to <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a> for further assistance`,
        icon: 'error',
        allowEnterKey: true,
        allowEscapeKey: true,
        allowOutsideClick: true,
        confirmButtonText: "Ok",
        showConfirmButton: true,
      })
    }
    while (!this.rental.rentalNumber) {
      // Wrap the setTimeout in a promise to wait for .5 seconds
      await new Promise(resolve => setTimeout(resolve, 500));
      // Fetch the rental information
      const result = await this._rentalService.getRentalByIDPromise(this.rental.id);
      if (result?.rentalNumber) {
        this.rental = result;
      }
    }

    this.replaceRental.emit(this.rental); // pass new rental to parent component
    this.bookingscomponent.asyncTotals(this.rental); // triggers weird reload thing
    this.isQuickBook = false;
    this.isQuickBookChange.emit(false);

    this.displayEditBooking(this.rental, 'hide');
    this.savingRentalChanges = false;
  }

  private applyOverrideConfig(availabilityOverrideConfig?: AvailabilityOverrides): void {
    if (!this.isAvailabilityOverride) {
      // this.availabilityOverrideConfig = null;
      this.availabilityOverrideConfig = JSON.parse(JSON.stringify(this.defaultAvailabilityOverrideConfig));
    } else {
      this.availabilityOverrideConfig =
        availabilityOverrideConfig ? availabilityOverrideConfig :
          JSON.parse(JSON.stringify(this.toggledOnAvailabilityOverrideConfig))
    }
  }

  /* Determine whether or not availability override is toggled based off of rental */
  private determineAvailabilityOverrideToggleStatusOnRental(): void {
    // Some rentals aren't saved with an availabilityOverrideConfig param
    if (this.rental?.availabilityOverrideConfig && doObjectsHaveEqualValues(this.rental?.availabilityOverrideConfig, this.toggledOnAvailabilityOverrideConfig)) {
      this.isAvailabilityOverride = true;
    }
    else {
      this.isAvailabilityOverride = false;
    }

    /* Set the value of the availability override depending on which mode is toggled */
    this.applyOverrideConfig(this.rental?.availabilityOverrideConfig);
  }

  async displayEditBooking(rental, typeDisplay?) {

    this.determineAvailabilityOverrideToggleStatusOnRental(); // Resets availability override status on re-opening form

    if (this.rentalBeingModified) {
      Swal.fire({
        title: 'Cannot Edit Rental',
        html: 'Rental is already being edited below. Please finish editing rental or cancel your changes.',
        icon: 'error',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    }
    else {
      if (typeDisplay == 'show') {
        this.dateRangeFormDirty = false; // reset date range form status
        this.editBookingOpen = true;
        this.editDateRange = true;
      }
      else {
        this.editDateRange = false; // reset app-date-range values by forcing onInit to be ran again
        this.editBookingOpen = false;
        this.resetNoAvailabilitiesBooleans();
        this.newRentalData = {};
        this.previouslySelectedDatetimes = null;
      }

      // Set info if info is available on rental object
      if (rental?.dayStart && rental?.dayEnd) {
        this.dayStart = this.timeService.convertTimestampToLuxon(this.rental['dayStart'])
        this.dayEnd = this.timeService.convertTimestampToLuxon(this.rental['dayEnd'])
      }

      if (typeDisplay == 'show') {
        // Set the previously selected dates on form open / init
        this.previouslySelectedDatetimes = { dayStart: this.dayStart, dayEnd: this.dayEnd };
      }

      if (rental?.rentalLocationID) {
        this.selectedLocationID = this.rental.rentalLocationID;
      }

      this.psuedoCartObj = this.rental?.cartObj || { items: [] };

      const formedit = document.getElementById(
        'formedit'
      ) as HTMLDivElement | null;

      if (typeDisplay == 'show') {
        formedit.style.display = 'block';
        this.bookingInfo.controls['FirstName'].setValue(rental.userInfo.name);
        this.bookingInfo.controls['LastName'].setValue(rental.userInfo.lastName);
        this.bookingInfo.controls['Email'].setValue(rental.userInfo.email);
        this.bookingInfo.controls['Phone'].setValue(rental.userInfo.phone);
        this.bookingInfo.controls['Address'].setValue(rental.userInfo.address);
        this.bookingInfo.controls['Address2'].setValue(rental.userInfo.address2);
        this.bookingInfo.controls['city'].setValue(rental.userInfo.city);
        this.bookingInfo.controls['state'].setValue(rental.userInfo.state);
        this.bookingInfo.controls['zip'].setValue(rental.userInfo.zip);
        setTimeout(() => {
          this.bookingInfo.controls['country'].setValue(rental.userInfo.country);
        }, 500);

        this.initAddressForm(rental.userInfo.country);
      } else if (typeDisplay == 'hide') {
        formedit.style.display = 'none';
        // // txtDayStart.disabled = true;
        // // txtDayEnd.disabled = true;
        // this.BookingUserData.City = rental.userInfo.city;
        // this.BookingUserData.State = rental.userInfo.state;
        // this.BookingUserData.Zip = rental.userInfo.zip;
        // this.bookingInfo.controls['editDate'].setValue(false);
        // this.bookingInfo.controls['FirstName'].setValue('');
        // this.bookingInfo.controls['LastName'].setValue('');
        // this.bookingInfo.controls['Email'].setValue('');
        // this.bookingInfo.controls['Phone'].setValue('');
        // this.bookingInfo.controls['Address'].setValue('');
        // this.bookingInfo.controls['Address2'].setValue('');
        // this.bookingInfo.controls['dayStart'].setValue('');
        // this.bookingInfo.controls['dayEnd'].setValue('');
      }
    }
  }


  protected async toggleAvailabilityOverride($event: boolean) {
    this.isAvailabilityOverride = $event;
    this._cd.detectChanges();
    this.applyOverrideConfig();
    this._cd.markForCheck();
    return
  }

  /**
   * @description populate a rental object with the user's contact info from the form's current inputs
   */
  setCustomerInfo() {
    let userInfo = {
      name: this.bookingInfo.value.FirstName,
      lastName: this.bookingInfo.value.LastName,
      email: this.bookingInfo.value.Email,
      phone: this.bookingInfo.value.Phone?.internationalNumber || '',
      address: this.bookingInfo.value.Address,
      address2: this.bookingInfo.value.Address2,
      city: this.bookingInfo.value.city,
      state: this.bookingInfo.value.state,
      zip: this.bookingInfo.value.zip,
      country: this.bookingInfo.value.country
    }
    return userInfo;
  }

  /**
   * @description Check against previous rental to see if any changes were made, if so -> log
   */
  compareRentalCustomerInfo(rental, newRental) {
    let foundChange: boolean = false;
    var keys: string[] = [];

    for (var key in rental.userInfo) {
      keys.push(key);
    }

    let logCustomerInfo: string = 'Booking Information Edited';

    // Check for changes in the users contact info
    keys.forEach((element) => {
      if (rental.userInfo[element] != newRental.userInfo[element]) {
        foundChange = true;
        logCustomerInfo = logCustomerInfo + ', ' + element.charAt(0).toUpperCase() + element.slice(1) + ': ' + rental.userInfo[element] + ' -> ' + newRental.userInfo[element];
      }
    });

    return foundChange ? logCustomerInfo : null; // Return either the log string or null if no changes were found
  }
  async getDiscountCodeMap() {
    let discountCodeMap = {};
    let discountMapRes = await lastValueFrom(this.cartService.getStatusActiveDiscountsParamCompanyID(this.rental.companyID).pipe((take(1))))

    discountMapRes.forEach((code) => {
      discountCodeMap[code.discountTitle.toLowerCase()] = code; // discount code based off title (assumes that validation was done on creation to only allow status: (active + unique name)
    })
    this.discountCodeMap = discountCodeMap;
  }
  async validifyRentalDatesOnSave(newRental, rental) {
    /* DATE CHANGE OR OVERRIDE - If user is changing the date, OR override status of the rental */
    // 1.) Query necessary parameters for availability check
    let productIDArray: any[] = [];
    let productWidgetIDArray: any[] = [];
    let dayStart;
    let dayEnd;
    let swappableItems: any[] = [];

    // Availability check response saved in availability response
    if (Object.keys(this.newRentalData).length <= 0) {
      // If the dates weren't changed but user is still attempting to save (currently only utilized if user is saving a rental with an availability override change & no date change)
      await this.runAvailabilityAlgo(this.rental, this.rental.id, this.rental.dayStart, this.rental.dayEnd, { items: [] }, true); // uses rental.dayStart / dayEnd instead of newRentalData because no changes exist from the datepicker
      productIDArray = await this.inventoryPageService.getAllCurrentRentalIDs(this.rental.cartObj, true, false, false);
      productWidgetIDArray = await this.inventoryPageService.getAllCurrentRentalIDs(this.rental.cartObj, false, true, true);
      dayStart = this.rental.dayStart
      dayEnd = this.rental.dayEnd
    }
    else {
      await this.runAvailabilityAlgo(newRental, this.rental.id, this.newRentalData.dayStart, this.newRentalData.dayEnd, { items: [] }, true);
      productIDArray = JSON.parse(JSON.stringify(this.newRentalData['originalProductIDs']));
      productWidgetIDArray = JSON.parse(JSON.stringify(this.newRentalData['originalProductWidgetIDs']));
      dayStart = this.newRentalData['dayStart'];
      dayEnd = this.newRentalData['dayEnd'];
      swappableItems = this.newRentalData['swappableItems'];
    }
    const itemsAvailable: boolean = this.checkIfRentalIDsAreAvailable(productIDArray, productWidgetIDArray, dayStart, dayEnd, swappableItems);

    let productWidgetsAvailable = this.checkIfProductWidgetsAreAvailable(productWidgetIDArray, dayStart, dayEnd, swappableItems, this.newRentalData.type);

    let confirmAdjustmentsIfAny: boolean = true;
    if (itemsAvailable && productWidgetsAvailable) {
      // if the dates are exactly the same, (happens if user is simply changing the override status)
      if (Object.keys(this.newRentalData).length <= 0) {
        return { newRental, rental, itemsAvailable, productWidgetsAvailable, confirmAdjustmentsIfAny }; // no need for further processing if dates are the same
      }

      let newCartObj = await this.updateCartWithNewRentalData();
      // Assign a new rental based off the results of the availability check / timeslot selection
      newRental = await this.inventoryPageService.convertCartObjToRental(newCartObj, this.productGroupsMap, this.productsMap, this.discountCodeMap, this.rental, true)

      // Calculate total cost difference for alert / log
      let rentalCost = rental.cost;
      let newRentalCost = newRental.cost;
      let difference = newRentalCost - rentalCost

      // If no difference, no need to calculate message or $ log
      if (difference !== 0) {
        let title = '';
        let html = '';

        if (difference > 0) {
          title = 'Order Total Increase'
          html = 'Saving the rental will cause the rental cost to increase by <b>$' + this.pricingService.roundDecimals(difference,2) + '</b>'
        }
        else if (difference < 0) {
          title = 'Order Total Decrease'
          html = 'Saving the rental will cause the rental cost to decrease by <b>$' + this.pricingService.roundDecimals(difference,2) + '</b>'
        }

        let swalResponse2 = await Swal.fire({
          title: title,
          html: html,
          icon: 'question',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "Confirm",
          showCancelButton: true,
          showConfirmButton: true,
        })

        if (!swalResponse2.isConfirmed) {
          confirmAdjustmentsIfAny = false;
        }
      }

      // Modify properties on newRental that were not included in the conversion / are necessary
      newRental.lastcost = rental.cost; // this is the old rental cost

      // Newrental is created via Rental conversion, which does not include the rental ID / rental num (due to it usually being done before a rental is created)
      newRental.id = rental.id
      newRental.rentalNumber = rental.rentalNumber

    }
    return { newRental, rental, itemsAvailable, productWidgetsAvailable, confirmAdjustmentsIfAny };
  }


  setRentalDatesAndAddDateChangeLogs(newRental, rental, logDetailsArr) {
    let timezoneOfOriginalRental = rental.timeZone;
    // CANNOT USE THIS.RENTAL OR RENTAL PARAM BEING PASSED IN - THEY ARE BEING CHANGED SOMEHOW MID METHOD RUN?!?
    // Convert dates to luxon for comparison, display, etc
    let newRentalLuxon = { dayStart: this.timeService.convertTimestampToLuxon(this.newRentalData.dayStart), dayEnd: this.timeService.convertTimestampToLuxon(this.newRentalData.dayEnd) }; // already set to the current timezone (selected in input) when returned via the modal
    let rentalLuxon = { dayStart: this.timeService.convertTimestampToLuxon(rental.dayStart).setZone(timezoneOfOriginalRental), dayEnd: this.timeService.convertTimestampToLuxon(rental.dayEnd).setZone(timezoneOfOriginalRental) };

    // Assign javascript date variants on the newRental for firestore compatibility
    newRental.dayStart = newRentalLuxon.dayStart.toJSDate();
    newRental.dayEnd = newRentalLuxon.dayEnd.toJSDate();

    // Compare the old to the newly inputed dates. If they're the same, no need to log
    let dayStartDiff = rentalLuxon.dayStart.hasSame(newRentalLuxon.dayStart, 'minute')
    let dayEndDiff = rentalLuxon.dayEnd.hasSame(newRentalLuxon.dayEnd, 'minute')

    /* Date Change Log */
    if (!dayStartDiff || !dayEndDiff) { // If either date difference is different, then we need to log the change
      let dateChangeLog = 'Date/Time change, old: "' +

        // Track the date change if necessary
        rentalLuxon.dayStart.toLocaleString(DateTime.DATETIME_SHORT) +
        ' - ' +
        rentalLuxon.dayEnd.toLocaleString(DateTime.DATETIME_SHORT) +
        '"' +
        ' new: "' +
        newRentalLuxon.dayStart.toLocaleString(DateTime.DATETIME_SHORT) +
        ' - ' +
        newRentalLuxon.dayEnd.toLocaleString(DateTime.DATETIME_SHORT) + '"';
      logDetailsArr.push(dateChangeLog);
    }

    return { newRental, logDetailsArr }
  }

  async editBooking(rental) {
    let editBookingPrompt = await Swal.fire({
      title: 'Are you sure you want to edit the Booking?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, edit it!',
    })
    if (!editBookingPrompt.isConfirmed) return // exit early if user cancels
    this.savingRentalChanges = true;

    // Init variables
    let logDetailsArr: any[] = [];
    let newRental: Rental = JSON.parse(JSON.stringify(this.rental));
    let ogRental: Rental = JSON.parse(JSON.stringify(this.rental));
    let changeInOverrideStatus = false; // Needs to look for changes, in case user only updates status and not date (needs to know run the availability again)

    // Detects difference in local vs db override status (needed for further processing)
    if (Boolean(ogRental.availabilityOverrideConfig) !== this.isAvailabilityOverride) {
      changeInOverrideStatus = true;
    }

    /* DATE RANGE or OVERRIDE STATUS CHANGE */
    // !IMPORTANT! - If a date change, a new rental object is created with the new dates, this object will be then be utilized for further processing
    if ((Object.keys(this.newRentalData).length > 0 || changeInOverrideStatus) && this.itemsInCartObj) {
      let itemsAvailable, productWidgetsAvailable = false;
      let confirmAdjustmentsIfAny = true;
      ({ newRental, rental, itemsAvailable, productWidgetsAvailable, confirmAdjustmentsIfAny } = await this.validifyRentalDatesOnSave(newRental, rental)); // Check date / item validity again before save
      // ({ newRental, logDetailsArr } = this.setRentalDatesAndAddDateChangeLogs(newRental, rental, logDetailsArr));// Sets the newRental daystart and day end, checks to see if there was a change in the date and creates a date change log if necessary
      if (!itemsAvailable || !productWidgetsAvailable) this.noAvailabilities.onTime = true; // If no pass on availability check, show error message

      if (!itemsAvailable || !productWidgetsAvailable || !confirmAdjustmentsIfAny) {
        this.savingRentalChanges = false; // hide spinner
        return // exit early if no pass on availability check
      }
    }
    else if ((Object.keys(this.newRentalData).length > 0) && !this.itemsInCartObj) {
      try {
        ({ newRental, logDetailsArr } = this.setRentalDatesAndAddDateChangeLogs(newRental, rental, logDetailsArr));// Sets the newRental daystart and day end, checks to see if there was a change in the date and creates a date change log if necessary
        let hasAvailabilities = await this.noItemsInCartAvailCheck(newRental, this.rental.id, this.newRentalData.dayStart, this.newRentalData.dayEnd, this.newRentalData?.type || '');

        if (!hasAvailabilities) {
          this.noAvailabilities.onTime = true; // Cheking availbility for specific time
          this.savingRentalChanges = false;
          return
        }
      }
      catch (err) {
        this.savingRentalChanges = false;
        console.error(err)
        this.customerErrorMessage();
      }
    }

    else if ((changeInOverrideStatus) && !this.itemsInCartObj) { // Only a change in override status
      try {
        let hasAvailabilities = await this.noItemsInCartAvailCheck(this.rental, this.rental.id, this.rental.dayStart, this.rental.dayEnd, this.rental?.timeslotType || null);

        if (!hasAvailabilities) {
          this.noAvailabilities.onTime = true; // Cheking availbility for specific time
          this.savingRentalChanges = false;
          return
        }
      }
      catch (err) {
        this.savingRentalChanges = false;
        console.error(err)
        this.customerErrorMessage();
      }
    }

    if (Object.keys(this.newRentalData).length > 0) {
      newRental.timeslotType = this.newRentalData['type'] || null;
    }
    else {
      newRental.timeslotType = this.rental?.timeslotType || null;
    }

    newRental.cartObj.items = this.backendBookingsService.updateCartItemsToHaveMatchingTimeslotTypes(newRental.cartObj.items, newRental.timeslotType); // Update the cart items to have the matching timeslot type

    try {
      newRental = await this.backendBookingsService.convertAllRentalObjectTimesToJSDate(newRental); // Convert all times to JS date for firestore compatibility
    }
    catch (err) {
      console.error("Error converting rental times to JS date", err);
      this.customerErrorMessage();
    }

    /* CUSTOMER INFO CHANGES - Populate the newRental with the user's contact info from the form's current inputs */
    newRental['userInfo'] = this.setCustomerInfo();
    let logCustomerInfo = this.compareRentalCustomerInfo(rental, newRental) // Returns log difference or null
    if (logCustomerInfo) {
      logDetailsArr.splice(0, 0, logCustomerInfo) // insert at the beginning of the array (so it's log is created before the date change log if necessary)
    }

    /* AVAILABILITY OVERRIDE STATUS CHANGES */
    // Store override status on new version of rental
    newRental.availabilityOverrideConfig = this.availabilityOverrideConfig;

    // Compare the availability override on the rental (null, undefined, or object) to the current status of the override (boolean)
    if (changeInOverrideStatus) { // Detects difference in local vs db override status
      if (this.isAvailabilityOverride) { // If the override is enabled add the config to the message
        let message = "Availability override: disabled -> enabled";
        let enabledOverrides = "Bypassing the following availability checks: ";
        Object.keys(newRental.availabilityOverrideConfig).forEach((key, index) => {
          switch (key) {
            case 'overrideUnavailableHours':
              enabledOverrides += "Unavailable Hours";
              break;
            case 'overrideUnavailableDays':
              enabledOverrides += "Unavailable Days";
              break;
            case 'overrideSearchIncludesToday':
              enabledOverrides += "Current search day time adjustments";
              break;
            case 'overrideDOTW':
              enabledOverrides += "Day of the Week restrictions";
              break;
            case 'overridePastDatePrevention':
              enabledOverrides += "Past date prevention";
              break;
          }

          if (index < Object.keys(newRental.availabilityOverrideConfig).length - 1) { // Separate with commas if not last item
            enabledOverrides += ", ";
          }
        })
        logDetailsArr.push(message);
        logDetailsArr.push(enabledOverrides);
      }
      else {
        let message = "Availability override: enabled -> disabled";
        logDetailsArr.push(message);
      }
    }

    /* UPDATE RENTAL & LOGS IN FIRESTORE - BATCH */
    try {
      // ATOMIC Batch (update rental & create log)
      await this._logService.updateRentalWithLog(rental.id, newRental, logDetailsArr); // dates are converted to JS dates so firestore can create timestamps
      newRental = await this._rentalService.getRentalByIDPromise(rental.id); // ensures all rental date are firestore timestamps
    }
    catch (err) {
      this.savingRentalChanges = false;
      console.log(err)
      this.customerErrorMessage();
    }

    this.rental = newRental;
    this.savingRentalChanges = false;

    // Update other components
    this.replaceRental.emit(newRental); // pass new rental to parent component
    this.bookingscomponent.asyncTotals(newRental); // triggers weird reload thing
    this.displayEditBooking(newRental, 'hide'); // what does this do...
    // this.danielsPreviousCodeForReference(rental, lastrental);
  }

  customerErrorMessage() {
    Swal.fire({
      title: 'Processing Error',
      html: `This operation failed to complete. Please try again, or if the issue persists, reach out to <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a> for further assistance`,
      icon: 'error',
      allowEnterKey: true,
      allowEscapeKey: true,
      allowOutsideClick: true,
      confirmButtonText: "Ok",
      showConfirmButton: true,
    })
  }

  private async noItemsInCartAvailCheck(rental, rentalID: string, dayStart: DateTime, dayEnd: DateTime, timeslotType: string): Promise<boolean> {
    let psuedoCartObj = { items: [{ dayStart: dayStart, dayEnd: dayEnd, timeslotType: timeslotType || '' }] };
    await this.runAvailabilityAlgo(rental, rentalID, dayStart, dayEnd, psuedoCartObj, true);

    const availList: CartQuantities[] = await this.availability.availCartQuantitiesList();

    if (availList.length == 0) {
      return false; // No availabilities
    }
    else {
      return true
    }

  }

  async checkFirstLog(lastrental) {
    const firstlog = await this._logService.searchFirstLogCollection(
      lastrental.id,
      true
    );
    if (firstlog.length == 0) {
      let logaddon = {
        type: 'pdfRental',
        details: 'Original Rental Saved',
        change: 'first',
        rental: lastrental,
        timestamp: new Date(),
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
      };
      this._logService.addRentalPDFLog(this.rental.id, logaddon);
    }
  }
  async createCharge(chargecost) {
    let fleetTax = Number((chargecost * this.fleetTax) / 100);
    Swal.fire({
      title: 'Processing Charge',
      html: '<div style="height: 100px"><span>Please wait while processing charge...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
      allowEnterKey: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .pipe(take(1))
      .subscribe((result) => {
        let customerAccount = result.customer;
        let paymentMethod = result.payment_method;
        let metadata = result.metadata;
        let difference;
        var dataCharge = {
          title: 'Charge by edit date #' + this.rental.rentalNumber,
          customer: customerAccount,
          id: this.dataPI.paymentID,
          amount: this.pricingService.roundDecimals(chargecost,2),
          fleetTax: this.fleetTax,
          fleetTaxNumber: this.pricingService.roundDecimals(fleetTax,2),
          totaltotransfer: difference,
          paymentMethod: paymentMethod,
          destination: this.dataPI.stripeID,
          companyID: this.dataPI.paymentID,
          amountDestination: difference,
          transactiondata: metadata,
          isDeposit: false,
        };
        this.afFun
          .httpsCallable('createCharge')({
            data: dataCharge,
            isTesting: this._rentalService.isTesting,
          })
          .subscribe((datatransfer) => {
            if (datatransfer.code) {
              Swal.fire({
                title: 'Error creating Charge',
                html: 'Please contact the administrator to see the problem. Error code bpc01.',
                icon: 'error',
              });
            } else {
              if (this.rental.chargesID == undefined) {
                this.rental.chargesID = [
                  {
                    chargeID: datatransfer.id,
                    cost: this.pricingService.roundDecimals(chargecost,2),
                    date: new Date(),
                  },
                ];
              } else {
                this.rental.chargesID.push({
                  chargeID: datatransfer.id,
                  cost: this.pricingService.roundDecimals(chargecost,2),
                  date: new Date(),
                });
              }
              datatransfer['type'] = 'charge';
              datatransfer['rentalID'] = this.rental.id;
              datatransfer.metadata['type'] = 'charge';
              datatransfer.metadata['type2'] = 'editrental';
              datatransfer.metadata['rentalID'] = this.rental.id;
              datatransfer.metadata['title'] =
                'Charge cause increment of total';
              datatransfer.metadata['companyID'] = this.rental.companyID;
              this._rentalService.addStripeTransaction(datatransfer);
              this._orderService.updatePrepared(this.rental.id, this.rental);
              Swal.fire({
                title: 'The charge was made and the changes were saved',
                icon: 'success',
              });
            }
          });
      });
  }
  async createRefund(difference) {
    Swal.fire({
      title: 'Saving Booking',
      html: '<div style="height: 100px"><span>Please wait while processing refund...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
      allowEnterKey: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .pipe(take(1))
      .subscribe((result) => {
        let pricePayment = result.amount / 100;
        let status = false;
        let paymentID = '';
        let position = 0;
        let cost = 0;
        if (this.pricingService.roundDecimals(difference,2) < pricePayment) {
          status = true;
          paymentID = result.id;
        } else {
          for (let i = 0; i < this.rental.chargesID.length; i++) {
            const element = this.rental.chargesID[i];
            if (this.pricingService.roundDecimals(difference,2) < element.cost) {
              cost = element.cost;
              position = i;
              status = true;
              paymentID = element.chargeID;
            }
          }
        }
        if (status) {
          let dataRefund = {
            isTesting: this._rentalService.isTesting,
            stripeID: this.dataPI.stripeID,
            paymentID: paymentID,
            amount: this.pricingService.roundDecimals(difference,2) * 100,
            description: 'Refund cause total decrease',
            companyID: this.rental.companyID,
            email: this.rental.userInfo.email,
            id: this.rental.id,
          };
          this.afFun
            .httpsCallable('refundPaymentAmount')(dataRefund)
            .subscribe((resultrefund) => {
              if (resultrefund.code) {
                Swal.fire({
                  title: 'Error creating Refund',
                  html: 'Please contact the administrator to see the problem. Error code bpr01.',
                  icon: 'error',
                });
              } else {
                if (this.rental.refundsID == undefined) {
                  this.rental.refundsID = [
                    {
                      refundID: resultrefund.id,
                      amount: this.pricingService.roundDecimals(difference,2),
                      date: new Date(),
                    },
                  ];
                } else {
                  this.rental.refundsID.push({
                    refundID: resultrefund.id,
                    amount: this.pricingService.roundDecimals(difference,2),
                    date: new Date(),
                  });
                }
                resultrefund['type'] = 'refund';
                resultrefund['rentalID'] = this.rental.id;
                resultrefund.metadata['type'] = 'refund';
                resultrefund.metadata['type2'] = 'editrental';
                resultrefund.metadata['rentalID'] = this.rental.id;
                resultrefund.metadata['title'] =
                  'Refund cause decrease of total';
                resultrefund.metadata['companyID'] = this.rental.companyID;
                this._rentalService.addStripeTransaction(resultrefund);
                this._rentalService.updateRental(this.rental, this.rental.id);
                this._logService.addRentalLog(
                  this.rental.id,
                  'Booking Change, refund amount: ' + this.pricingService.roundDecimals(difference,2)
                );
                $('#ProductModal').modal('hide'); //Hide the modal
                Swal.fire({
                  title: 'The refund was made and the changes was saved',
                  icon: 'success',
                });
              }
            });
        }
      });
  }
  onlyNumber(event: any, length: number) {
    if (event.target.value.length > length - 1) {
      return false;
    } else {
      const charCode = event.which ? event.which : event.keyCode;
      return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }
  }

  getRental(id) {
    this._rentalService.companyID = this.rental.companyID;
    this._rentalService
      .getDefaultLocation(this.companyObj.defaultLocation)
      .then((location) => {
        this.productLocation = location
      }).catch((reason) => {
        console.error(`error getting product location: ${reason}`)
        this.productLocation = this.companyObj.defaultLocation;
      })

    this.smartwaiversigned = false;
    this._afs
      .collection('rentals')
      .doc(id)
      .ref.onSnapshot(async (data) => {
        this.rental = data.data() as Rental;
        this.rental.id = data.id;
        this._rentalService.companyID = this.rental.companyID;
        this.productLocation = await this._rentalService.getDefaultLocation(
          this.companyObj.defaultLocation
        );
        if (this.companyObj.isSmartWaiver) {
          this.smartwaiveractive = true;
          await this._rentalService
            .getSmartWaiver(this.rental.id)
            .subscribe((waiver) => {
              if (waiver.length > 0) {
                this.smartwaiversigned = true;
                this.smartwaiverdata = waiver;
              } else {
                this.smartwaiversigned = false;
              }
            });
        } else {
          this.smartwaiveractive = false;
        }

        for (var i = 0; i < this.rental.products.length; i++) {
          if (this.rental.products[i].isPrepared == true) {
            this.statusPrepared = true;
          } else {
            this.statusPrepared = false;
            break;
          }
        }
        for (var i = 0; i < this.rental.products.length; i++) {
          if (this.rental.products[i].isCheckedOut == true) {
            this.statusCheckedOut = true;
          } else {
            this.statusCheckedOut = false;
            break;
          }
        }
        for (var i = 0; i < this.rental.products.length; i++) {
          if (this.rental.products[i].isCheckedIn == true) {
            this.statusCheckedIn = true;
          } else {
            this.statusCheckedIn = false;
            break;
          }
        }

        setTimeout(() => {
          this.getWaiversByRental();
          this._cd.detectChanges();
        }, 1500);
      });
  }

  async getWaiversByRental() {
    this.waiversArray = [];
    this.multipleWaivers = true;
    if (this.companyObj.multipleWaivers) {
      let promises = [];
      this.rental.productsID.forEach((productid, i) => {
        let promise = new Promise(async (resolve, reject) => {
          const product = await this._rentalService.getWaiversByProduct(
            productid
          );
          if (product.waivers) {
            product.waivers.forEach(async (waiverid) => {
              const waiver = await this._rentalService.getWaiverById(
                this.companyObj.id,
                waiverid
              );
              if (waiver?.multipleSignature) {
                this.waiversArray.push(waiverid);
              } else {
                const found = this.waiversArray.find(
                  (element) => element == waiverid
                );
                found ? '' : this.waiversArray.push(waiverid);
              }
            });
          }
          resolve(true);
        });
        promises.push(promise);
      });
      Promise.all(promises).then((_) => {
        setTimeout(() => {
          this.waiversSigned = this.rental.waiversSigned
            ? this.rental.waiversSigned.length
            : 0;
        }, 500);
      });
    } else {
      if (this.companyObj.multipleSignature) {
        this.rental.productsID.forEach((productid, i) => {
          this.waiversArray.push(productid);
        });
        this.waiversSigned = this.rental.waiversSigned
          ? this.rental.waiversSigned.length
          : 0;
      } else {
        this.waiversArray.push(this.rental.productsID[0]);
        this.waiversSigned = this.rental.waiversSigned
          ? this.rental.waiversSigned.length
          : 0;
      }
    }
  }
  addNoteClick() {
    const divFormNote = document.getElementById(
      'formNote'
    ) as HTMLElement | null;
    const linkAdd = document.getElementById(
      'AddlinkNote'
    ) as HTMLElement | null;
    const linkCancel = document.getElementById(
      'cancelLinkNote'
    ) as HTMLElement | null;
    divFormNote.style.display = 'block';
    linkAdd.style.display = 'none';
    linkCancel.style.display = 'block';
  }
  cancelAddNoteClick() {
    const divFormNote = document.getElementById(
      'formNote'
    ) as HTMLElement | null;
    const linkAdd = document.getElementById(
      'AddlinkNote'
    ) as HTMLElement | null;
    const linkCancel = document.getElementById(
      'cancelLinkNote'
    ) as HTMLElement | null;
    divFormNote.style.display = 'none';
    linkAdd.style.display = 'block';
    linkCancel.style.display = 'none';
  }
  addNote() {
    Swal.fire({
      title: 'The note has been added',
      icon: 'success',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.cancelAddNoteClick();
      }
    });
    let note = 'Note: ' + this.noteForm;
    this._logService.addRentalLog(this.rental.id, note);
  }
  openLogs() {
    this.getLogs(this.rental.id);
  }
  convertTimeStamp(data: any) {
    //Parameter timestamp
    var ds = new Date(data.seconds * 1000); //Get the date of the timestamp
    var date2 = moment(ds).format('LLL'); //Format hh:mm am
    return date2; //Return
  }

  selectedValue = 'Select Item';

  onChange(action: any) {
    this.bookingNextStatus.openModal(this.rental, action);
  }

  updateTopBar() {
    if (this.rental.isCheckedOut && !this.rental.isCheckedIn) {
      this.classStep1 = 'stepComplete';
      this.classStep2 = 'stepCurrent';
      document
        .getElementById('check-in-part-trigger')
        .removeAttribute('disabled');
    } else if (this.rental.isCheckedIn && !this.rental.isComplete) {
      this.classStep1 = 'stepComplete';
      this.classStep2 = 'stepComplete';
      this.classStep3 = 'stepCurrent';
      document
        .getElementById('check-in-part-trigger')
        .removeAttribute('disabled');
      document
        .getElementById('completion-part-trigger')
        .removeAttribute('disabled');
    } else if (this.rental.isComplete) {
      this.classStep1 = 'stepComplete';
      this.classStep2 = 'stepComplete';
      this.classStep3 = 'stepComplete';
      document
        .getElementById('check-in-part-trigger')
        .removeAttribute('disabled');
      document
        .getElementById('completion-part-trigger')
        .removeAttribute('disabled');
    }
    this._cd.detectChanges();
  }

  deleteBtnClick(file) {
    this._afs
      .collection('rentals')
      .doc(this.rental.id)
      .collection('files')
      .doc(file.id)
      .update({
        isActive: false,
      });
  }

  clientCheckOut(event: any) {
    event.isCheckedOut = true; // = "Client Checked Out"
    this.rental.isCheckedOut = true;
    if (event.statusDate) {
      event.statusDate['isCheckedOut'] = new Date();
    }
    this._afs.collection('rentals').doc(event.id).update(event);
    this.currentTab = 'Check In';
    this.stepper.next();
    this.updateTopBar();
  }

  nextPage(str) {
    this.stepper.next();
    this.currentTab = str;
  }

  clientCheckedIn(event: any) {
    event.isCheckedIn = true;
    this.rental.isCheckedIn = true;
    if (event.statusDate) {
      event.statusDate['isCheckedIn'] = new Date();
    }
    this._afs.collection('rentals').doc(event.id).update(event);
    this.currentTab = 'Complete';
    this.stepper.next();
    this.updateTopBar();
  }

  bookingComplete(rental: any) {
    this.rental.isComplete = true;
    if (rental['statusDate']) {
      rental['statusDate']['isComplete'] = new Date();
    }
    rental['isComplete'] = true;
    this._afs.collection('rentals').doc(rental.id).update(rental);
    this.stepper.next();
    this.updateTopBar();
  }

  itemDamagedClick() {
    this.rental.isDamaged = !this.rental.isDamaged;
  }

  itemTuneUpClick() {
    this.rental.isTuneUp = true;
  }

  itemCleaningClick() {
    this.rental.isCleaning = true;
  }

  onSubmit() { }
  addPaymentMethod() {
    this.fullpayment = false;
    this.amountToPay = 0;
    let FullAmountPay = this.pricingService.roundDecimals((
      this.rental.amountPending +
      this.rental.amountPending * (this.fleetTax / 100) +
      this.rental.amountPending * (this.companyTax / 100)
    ),2);
    Swal.fire({
      title: 'The amount to be charged is $' + FullAmountPay,
      icon: 'info',
      text: 'Do you want to continue or do you want to change the amount?',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Change Amount',
      showDenyButton: true,
      denyButtonText: 'Cancel',
      allowEnterKey: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      customClass: {
        actions: 'my-actions',
        denyButton: 'order-1 right-gap',
        cancelButton: 'order-2',
        confirmButton: 'order-3',
      },
    }).then((result) => {
      if (result.isDenied) {
        return;
      } else if (result.isConfirmed) {
        this.amountToPay = this.rental.amountPending;
        this.fullpayment = true;
        this.openPaymentModal();
      } else if (result.isDismissed) {
        Swal.fire({
          title: 'Please put an amount to charge',
          icon: 'info',
          allowOutsideClick: false,
          input: 'text',
          inputLabel: 'Amount',
          inputPlaceholder: 'Enter the amount to charge',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          showLoaderOnConfirm: true,
          preConfirm: (value) => {
            if (
              value <= 0 ||
              value == null ||
              value == undefined ||
              value == '' ||
              isNaN(Number(value))
            ) {
              Swal.showValidationMessage(`Please enter a valid amount`);
            } else {
              return value;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.fullpayment = false;
            this.amountToPay = Number(result.value);
            this.openPaymentModal();
          }
        });
      } else {
        return;
      }
    });
  }

  createPaymentMethod() {
    Swal.fire({
      title: 'Loading Payment Form...',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    if (this.applytaxes) {
      this.amountToPAYwTaxes =
        this.amountToPay +
        this.amountToPay * (this.fleetTax / 100) +
        this.amountToPay * (this.companyTax / 100);
    } else {
      this.amountToPAYwTaxes = this.amountToPay;
    }
    const fleetTax = this.companyObj.fleetTax || 0
    const companyTax = this.companyObj.companyTax || 0
    let rentalObj = {
      fleetTax: (fleetTax / 100) || 0,
      companyTax: (companyTax / 100) || 0,
      companyTaxes: this.companyTaxes || 0,
      fleetTaxes: this.fleetTaxes || 0,
      cost: this.amountToPAYwTaxes,
      stripeID: this.companyObj.isTesting ? this.companyObj.stripeIDTest : this.companyObj.stripeID,
      companyID: this.rental.companyID,
      customer: this.customer.id
    };
    this.afFun
      .httpsCallable('createPaymentIntent')({
        rental: rentalObj,
        isTesting: this._rentalService.isTesting,
        rentalID: this.rental.id,
        isTest: true,
        //se manda el rental ID para guardar en metadata cus_NchKH5KASKd7vY
      })
      .subscribe(async (result) => {
        if (!result) {
          this.isError = true;
          this.isLoading = false;
          this.toastr.error(result.data.error, 'Error');
          return;
        }
        this.paymentIntentID = result.id;
        this.clientSecret = result.client_secret;
        const clientSecret = result.client_secret;
        const appearance = {
          theme: 'stripe',
        };

        const elements = this.stripe.elements({ appearance, clientSecret });
        this.elementTest = elements;

        //options for payment element
        const options = {
          wallets: {
            applePay: 'auto',
          },
          fields: {
            billingDetails: {
              name: 'auto',
              phone: 'auto',
              email: 'auto',
              address: {
                line1: 'auto',
                line2: 'auto',
                city: 'auto',
                state: 'auto',
                postalCode: 'auto',
                country: 'auto',
              },
            },
          },
        };

        this.paymentElement = elements.create('payment', options);
        this.paymentElement.mount('#payment-element');
        this.paymentElement.on('ready', (event) => {
          this._rentalService.companyID = this.rental.companyID;
          Swal.close();
          this.isHidden = false;
          this.isLoading = false;
        });
      });
  }

  validateCustomer(){
    this.submittedpayment = true;
    if (this.checkoutForm.valid) {
      this.createCustomer();
    }
  }
  createCustomer() {
    Swal.fire({
      title: "Create Customer",
      html: "In order to create a new payment method, it is necessary to define a new customer. Are you sure the information is correct? You will not be able to edit it after continuing.",
      icon: "question",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      reverseButtons: true,
      showCancelButton: true
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Saving customer..."
        })
        Swal.showLoading();
        if (this.rental) {
          this.rental['stripeID'] = this.companyObj.isTesting ? this.companyObj.stripeIDTest : this.companyObj.stripeID;
          let objCustomer = {
            name: this.checkoutForm.value.firstName,
            lastName: this.checkoutForm.value.lastName,
            email: this.checkoutForm.value.email,
            phone: this.checkoutForm.value.Phone?.internationalNumber,
            address: this.checkoutForm.value.address,
            address2: this.checkoutForm.value.address2,
            city: this.checkoutForm.value.city,
            state: this.checkoutForm.value.state,
            zip: this.checkoutForm.value.zip
          };
          this.afFun
            .httpsCallable('createSingleCustomer')({
              customerInfo: objCustomer,
              stripeID: this.rental['stripeID'],
              isTesting: this._rentalService.isTesting,
            })
            .subscribe(
              (customer) => {
                Swal.close();
                this.customer = customer;
                this.customerCreated = true;
                this.submittedpayment = false;
                if (this.typecharge == "charge" || this.typecharge == "deposit") {
                  this.createPaymentMethodNew(this.typecharge, this.chargedescription);
                } else {
                  this.createPaymentMethod();
                }
              },
              (error) => {
                Swal.close();
                console.error("Error creating customer:", error);
                Swal.fire(
                  'Error',
                  'An error occurred while creating the customer',
                  'error'
                );
              }
            );
        }
      }
    })
  }
  async handleSubmit(e) {
    this.submittedpayment = true;
    Swal.fire({
      title: 'Processing Payment',
      text: 'Please wait...',
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: false,
    });
    Swal.showLoading();
    let isInvalid = document.querySelector('.is-invalid');
    if (isInvalid) {
      let input = document.getElementById(isInvalid.id) as HTMLInputElement;
      input.style.borderColor = '#ced4da';
      isInvalid.classList.remove('is-invalid');
      isInvalid.nextElementSibling.innerHTML = '';
    }

    this.chargeamount = Number(this.chargeamount)

    let billingDetails, error, paymentIntent;
    const
      rentalUpdateError = `<strong>Rental</strong>: An error occurred while updating rental. Please contact customer support, <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a>, the following:`,
      paymentConfirmError = `<strong>Payment</strong>: An error occurred while handling the payment. Please contact customer support, <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a>, with the following:`,
      defaultPaymentError = `<strong>Payment</strong>: An error occurred while handling the payment. Please contact customer support, <a href="mailto:${environment.app.contactEmail}">${environment.app.contactEmail}</a>.`
      ;

    try {
      // Define customer information to the payment method
      e.preventDefault();
      const url = new URL(window.location.href).toString();
      const billDetails = {
        name: (this.checkoutForm.value.firstName + " " + this.checkoutForm.value.lastName),
        email: this.checkoutForm.value.email,
        phone: this.checkoutForm.value.phone.internationalNumber,
        address: {
          city: this.checkoutForm.value.city,
          country: this.checkoutForm.value.country || "US",
          line1: this.checkoutForm.value.address,
          line2: this.checkoutForm.value.address2 || "",
          postal_code: this.checkoutForm.value.zip,
          state: this.checkoutForm.value.state,
        },
      };
      billingDetails = billDetails;

      const response = await this.stripe.confirmPayment({
        elements: this.elementTest,
        confirmParams: {
          return_url: url,
          payment_method_data: {
            billing_details: billDetails,
          }
        },
        redirect: 'if_required',
      });
      // handle errors
      if (response.error) {
        const
          displayErrors = ["validation", "card_error"],
          errType = response.error.type || 'missing'
          ;
        if (response.error && displayErrors.includes(errType)) {
          error = response.error
        } else {
          error = defaultPaymentError
        }
        this._logService.addRentalLog(this.rental.id, `Payment error(type: ${errType}), ${response.error.message} (code: ${response.error.code || ''}, decline_code: ${response.error.decline_code || ''})`)
      } else {
        paymentIntent = response.paymentIntent;
      }

    } catch (e) {
      console.error("failure updating rental or capturing payment", e)
      let friendly // human friendly error message
      if (!billingDetails) {
        friendly = rentalUpdateError
      } else {
        friendly = paymentConfirmError
      }
      error = `<p>${friendly}</p><p style="color: red;"><code>"${e}"</code>`
    }

    if (error) {
      const msg = (error.message) ? error.message : error

      Swal.close();
      this.submitted = false;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: msg,
        confirmButtonText: 'I got it',
      });

    } else {
      let type = (this.typecharge === 'deposit' || this.typecharge === 'charge')
        ? this.typecharge
        : (this.typecharge === 'completeAmount' || this.typecharge === 'complete')
          ? 'complete'
          : null;
          console.log(type)
      this.afFun
        .httpsCallable('retrievePayment')({
          paymentID: paymentIntent.id,
          isTesting: this.companyObj.isTesting,
          stripeID: this.companyObj.isTesting ? this.companyObj.stripeIDTest : this.companyObj.stripeID,
        })
        .pipe(take(1))
        .subscribe((payment) => {
          payment['type'] = type;
          payment['rentalID'] = this.rental.id;
          this._rentalService.addStripeTransaction(payment);
          this.resetAllCards();
          Swal.close();
          Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
          }).fire({
            title: "Payment Confirmation",
            icon: 'success',
            text: "Process payment submitted.",
          })
          Swal.hideLoading();
          $('#paymentModal').modal('hide');

          // don't save updated totals until after a successful payment
          this.rental.amountPending = this.pricingService.roundDecimals(this.pendingAmount,2);
          this.rental.amountPendingAbs = this.pricingService.roundDecimals(this.pendingAmount,2);
          this._logService.addRentalLog(this.rental.id, 'Payment Method Added');
          
          console.info(`updateRentalAmounts(${this.rental.id}) type(${this.typecharge})`, {
            charge: this.pricingService.roundDecimals(this.chargeamount,2),
            pending: this.pricingService.roundDecimals(this.pendingAmount,2),
            abs: this.rental.amountPendingAbs
          });
          
          this._firestoreService.updateDocument(Collection.Rentals, this.rental.id, { amountPending: this.pricingService.roundDecimals(this.pendingAmount,2), amountPendingAbs: this.pricingService.roundDecimals(this.pendingAmount,2) }, true);
          this.bookingscomponent.asyncTotals(this.rental);
        });
    }
  }

  openPaymentModal() {
    this.customer = {};
    this.customerCreated = false;
    Swal.fire({
      title: "Loading..."
    })
    Swal.showLoading();
    this.checkoutForm.get('firstName').setValue(this.rental.userInfo.name);
    this.checkoutForm.get('lastName').setValue(this.rental.userInfo.lastName);
    this.email = this.rental.userInfo.email;
    this.checkoutForm.get('email').setValue(this.rental.userInfo.email);
    this.initAddressForm(this.rental.userInfo.country);
    this.checkoutForm.get('phone').setValue(this.rental.userInfo.phone);
    this.checkoutForm.get('address').setValue(this.rental.userInfo.address);
    this.checkoutForm.get('city').setValue(this.rental.userInfo.city);
    this.checkoutForm.get('zip').setValue(this.rental.userInfo.zip);
    this.checkoutForm.get('country').setValue(this.rental.userInfo.country);
    setTimeout(() => {
      this.checkoutForm.get('state').setValue(this.rental.userInfo.state);
      Swal.close();
    }, 1000);
    $('#paymentModal').modal('show');
  }
  createPaymentMethodNew(type, reason?) {
    Swal.fire({
      title: 'Loading Payment Form...',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    let description = '';
    description = 'Charge to ' + this.rental.paymentID;
    if (this.applytaxes) {
      this.amountToPAYwTaxes =
        this.amountToPay +
        this.amountToPay * (this.companyObj.fleetTax / 100) +
        this.amountToPay * (this.companyObj.companyTax / 100);
    } else {
      this.amountToPAYwTaxes = this.amountToPay;
    }
    const fleetTax = this.companyObj.fleetTax || 0
    const companyTax = this.companyObj.companyTax || 0
    let rentalObj = {
      fleetTax: this.applytaxes ? (fleetTax / 100) : 0,
      companyTax: this.applytaxes ? (companyTax / 100) : 0,
      companyTaxes: this.amountToPay * (companyTax / 100) || 0,
      fleetTaxes: (this.amountToPay * (fleetTax / 100)) || 0,
      cost: this.amountToPAYwTaxes,
      stripeID: this.companyObj.isTesting ? this.companyObj.stripeIDTest : this.companyObj.stripeID,
      companyID: this.rental.companyID,
      description: description,
      title: reason,
      userName:
        this._currentUser.currentUser.firstName +
        ' ' +
        this._currentUser.currentUser.lastName,
      pending: this.typecharge == 'deposit',
      customer: this.customer.id
    };
    this.afFun
      .httpsCallable('createPaymentIntent2')({
        rental: rentalObj,
        isTesting: this._rentalService.isTesting,
        rentalID: this.rental.id,
        isTest: true,
      })
      .pipe(take(1))
      .subscribe(async (result) => {
        if (!result) {
          this.isError = true;
          this.isLoading = false;
          this.toastr.error(result.data.error, 'Error');
          return;
        }
        this.paymentIntentID = result.id;
        this.clientSecret = result.client_secret;
        const clientSecret = result.client_secret;
        const appearance = {
          theme: 'stripe',
        };

        const elements = this.stripe.elements({ appearance, clientSecret });
        this.elementTest = elements;

        //options for payment element
        const options = {
          wallets: {
            applePay: 'auto',
          },
          fields: {
            billingDetails: {
              name: 'auto',
              phone: 'auto',
              email: 'auto',
              address: {
                line1: 'auto',
                line2: 'auto',
                city: 'auto',
                state: 'auto',
                postalCode: 'auto',
                country: 'auto',
              },
            },
          },
        };

        this.paymentElement = elements.create('payment', options);
        this.paymentElement.mount('#payment-element');
        this.paymentElement.on('ready', (event) => {
          this._rentalService.companyID = this.rental.companyID;
          Swal.close();
          // Handle ready event
          this.isHidden = false;
          this.isLoading = false;
        });
      });
  }

  displayData(passedRental: Rental) {
    this.rental = passedRental;
    this._cd.detectChanges();
  }

  uploadPDFWaiver(id: string, event: any) {
    let pdfFile = event.target.files;
    for (let i = 0; i < pdfFile.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(pdfFile[i]);
      reader.onloadend = () => {
        this.pdfco.push(reader.result);
        this._orderService
          .uploadPDF(id, reader.result, id + '_waiver', 'CheckedOut')
          .then((pdfFile) => {
            this.rental.waiver = [pdfFile];
            this._orderService.updateWaiver(this.rental.id, this.rental);
            this._logService.addRentalLog(
              this.rental.id,
              'Waiver Document Uploaded'
            );
            Swal.fire({
              title: 'Waiver Document Uploaded',
              icon: 'success',
            });
          });
      };
    }
  }
  openWaiver(waiver) {
    window.open(waiver, '_blank');
  }

  getFileURL(event) {
    let waiver = this.rental.waiver;
    if (waiver) {
      waiver.push(event.url);
    } else {
      waiver = [event.url];
    }
    this._afs.collection('rentals').doc(this.rental.id).update({
      waiver: waiver,
    });
  }

  generateReceipt() {
    if (this.rental.chargesID) {
      delete this.rental['chargesID'];
    }
    if (this.rental.refundsID) {
      delete this.rental['refundsID'];
    }
    this.receiptRental = this.rental
    this.receiptRentalReady = this.rental
    Swal.fire({
      title: 'Do you want print the receipt or send an email?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Print`,
      denyButtonText: `Email`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Generating Receipt...',
          allowOutsideClick: false,
          allowEnterKey: false,
        });
        Swal.showLoading();
        this.receiptComponent.openReceipt(
          this._currentUser.currentUser.isDeveloper
        );
      } else if (result.isDenied) {
        Swal.close();
        Swal.fire({
          title: 'Do you want to send the receipt this email?',
          input: 'email',
          inputValue: this.rental.userInfo.email,
          inputLabel: 'Email',
          inputPlaceholder: 'Enter your email address',
          inputAttributes: {
            autocapitalize: 'off',
          },
          preConfirm: (email) => {
            if (!email) {
              Swal.showValidationMessage(`Please enter your email address`);
            }
          },
          showDenyButton: true,
          confirmButtonText: `Send`,
          denyButtonText: `Cancel`,
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Sending Receipt...',
              allowOutsideClick: false,
              allowEnterKey: false,
            });
            Swal.showLoading();
            this.receiptComponent.sendEmail(
              result.value,
              this._currentUser.currentUser.isDeveloper,
              this._currentUser.currentUser.email
            );
          } else if (result.isDenied) {
            Swal.fire({
              title: 'Cancelled',
              icon: 'error',
            });
          }
        });
      }
    });
  }


  senEmail() {
    //prepare variables
    let emailDiscount = 0;
    let subtotal = 0;

    let dayStart;
    let dayEnd;
    if (this.rental.rentalType == 'byHour') {
      dayStart = moment(this.rental.dayStart.seconds * 1000).format(
        'MMM DD, YYYY, hh:mm a'
      );
      dayEnd = moment(this.rental.dayEnd.seconds * 1000).format('- hh:mm a');
    } else if (this.rental.rentalType == 'byDay') {
      if (
        moment(this.rental.dayEnd.seconds * 1000).diff(
          moment(this.rental.dayStart.seconds * 1000),
          'days'
        ) < 1
      ) {
        dayStart = moment(this.rental.dayStart.seconds * 1000).format(
          'MMM DD, yyyy'
        );
        dayEnd = '';
      } else {
        dayStart = moment(this.rental.dayStart.seconds * 1000).format('MMM DD');
        dayEnd = moment(this.rental.dayEnd.seconds * 1000).format(
          '- MMM DD, yyyy'
        );
      }
    }

    let qr = new QRious({
      value:
        window.location.origin +
        '/partner/bookings-detail?id=' +
        this.rental.id,
    });

    // body for email

    let waiverUrl = this.domain + '/waiver/' + this.rental.id;
    qr = new QRious({
      value:
        window.location.origin +
        '/partner/bookings-detail?id=' +
        this.rental.id,
    });

    let companyName = 'Fleet Adventures';
    let address1 = '535 N 1300 E';
    let address2 = 'St. George, UT 84770';
    let phone = '(435) 251-8506';
    let fax = '(435) 251-8505';
    let url = 'www.fleetadventures.com';

    if (this.rental.companyID === 'wYxvJNGijJ5bJgNsckzg') {
      //Rapid Cycling
      companyName = 'Rapid Cycling';
      address1 = 'North Bluff Street';
      address2 = 'St. George, UT 84770';
      phone = '(435) 703-9880';
      fax = '(435) 703-9880';
      url = 'www.rapidcyclingbikes.com';
    }

    this.isPdfReady = true;
    this.rentalID = this.rental.id;

    this.productService
      .getDiscountByID(this.rental.discountID)
      .then((discount) => {
        ///////////////////////////////////////////////////////////////EMAIL AND PDF CREATION////////////////////////////////////////////////////////

        let body = '';
        body += '<table style="width: 100%; border-collapse: collapse;">';
        body += '<tbody>';
        body += '<tr>';
        body += '<tr>';
        body += '<td>' + companyName + '</td>';
        body += '<td style="text-align:right">Payment Receipt</td>';
        body += '</tr>';
        body += '<tr>';
        body += '<td>' + address1 + '</td>';
        body +=
          '<td style="text-align:right">Rental #' +
          this.rental.rentalNumber +
          '</td>';
        body += '</tr>';
        body += '<tr>';
        body += '<td>' + address2 + '</td>';

        body +=
          '<td style="text-align:right">Date: ' +
          dayStart +
          dayEnd +
          '</td>';
        body += '</tr>';
        body += '<tr>';
        body += '<td>Phone:' + phone + '</td>';
        body +=
          '<td style="text-align:right">Customer: ' +
          this.rental.userInfo.name +
          ' ' +
          this.rental.userInfo.lastName +
          '</td>';
        body += '</tr>';
        body += '<tr>';
        body += '<td>Fax:' + fax + '</td>';
        body +=
          '<td style="text-align:right">Rental Type ' +
          this.rental.rentalType +
          '</td>';
        body += '</tr>';
        body += '<tr>';
        body += '<td>' + url + '</td>';
        body +=
          '<td style="text-align:right">Total Paid: $' +
          this.pricingService.roundDecimals(this.rental.cost,2) +
          '</td>';
        body += '</tr>';
        body += '</tbody>';
        body += '</table>';

        body += '<table style="width: 100%; border-collapse: collapse;">';
        body += '<thead>';
        body += '<tr>';
        body +=
          '<th style=" border: 1px solid #ddd; background-color: #4283bd; color: white; padding: 8px;">Product</th>';
        body +=
          '<th style=" border: 1px solid #ddd; background-color: #4283bd; color: white; padding: 8px;">Items</th>';
        body +=
          '<th style=" border: 1px solid #ddd; background-color: #4283bd; color: white; padding: 8px;">Description</th>';
        body +=
          '<th style=" border: 1px solid #ddd; background-color: #4283bd; color: white; padding: 8px;">Total</th>';
        body += '</tr>';
        body += '</thead>';
        body += '<tbody>';

        this.rental.products.forEach((product) => {
          // let description = ''

          // if (product.description !== undefined) description = product.description

          if (
            discount !== null &&
            discount.productName !== undefined &&
            product.productName === discount.productName
          ) {
            emailDiscount = (product.cost * discount.discount) / 100;
          }

          body += '<tr>';
          body +=
            '<td style ="padding: 8px; border: 1px solid #ddd;">' +
            product.productName +
            ' - ' +
            product.productSize +
            '</td>';
          body +=
            '<td style ="padding: 8px; border: 1px solid #ddd;">' +
            'Rental' +
            '<br>';
          if (product.addons) {
            product.addons.forEach((addon) => {
              body += addon.title + '<br>';
            });
          }
          body += '</td>';
          body +=
            '<td style ="padding: 8px; border: 1px solid #ddd;">' +
            moment(this.rental.dayStart).format('LL') +
            ' ' +
            moment(this.rental.dayEnd).format('LL') +
            '<br>';
          if (product.addons) {
            product.addons.forEach((addon) => {
              if (
                addon.addonType === 'select' &&
                addon.optionsSelect[0].description !== ''
              ) {
                body += addon.optionsSelect[0].description + '<br>';
              }
              if (
                addon.addonType === 'number' &&
                addon.optionsSelect[0].qty > 0
              ) {
                body += addon.optionsSelect[0].qty + '<br>';
              }
            });
          }
          body += '</td>';

          subtotal += product.cost;

          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"> $ ' +
            this.pricingService.roundDecimals(product.cost,2) +
            '<br>';
          if (product.addons) {
            product.addons.forEach((addon) => {
              body += this.pricingService.roundDecimals(addon.optionsSelect[0].price,2) + '<br>';
              subtotal += addon.optionsSelect[0].price;
            });
          }
          body += '</td>';
          body += '</tr>';
        });

        if (this.rental.discountID !== undefined && discount !== null) {
          body += '<tr>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b>Discount</b></td>';

          if (discount.discountType === 'fixedAmount') {
            emailDiscount = 0;
            emailDiscount = discount.discount;

            body +=
              '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">- $ ' +
              this.pricingService.roundDecimals(emailDiscount,2) +
              '</b></td>';
          }

          if (discount.discountType === 'percentage') {
            if (discount.discountScope === 'rental') {
              emailDiscount = 0;
              emailDiscount = (subtotal * discount.discount) / 100;

              body +=
                '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">- $ ' +
                this.pricingService.roundDecimals(emailDiscount,2) +
                '</b></td>';
            }

            if (discount.discountScope === 'product') {
              body +=
                '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">- $ ' +
                this.pricingService.roundDecimals(emailDiscount,2) +
                '</b></td>';
            }
          }

          body += '</tr>';
        }

        if (
          this.rental.discount !== 0 &&
          this.rental.discount !== undefined &&
          this.rental.discount !== null
        ) {
          emailDiscount = this.rental.discount;

          body += '<tr>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b>Discount</b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">- $ ' +
            this.pricingService.roundDecimals(emailDiscount,2) +
            '</b></td>';
          body += '</tr>';
        }

        let haveDiscountInProduct = false;
        this.rental.products.forEach((product) => {
          if (product.productDiscount > 0) {
            emailDiscount += product.cost - product.productDiscount;
            haveDiscountInProduct = true;
          }
        });
        if (haveDiscountInProduct) {
          body += '<tr>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b>Discount</b></td>';
          body +=
            '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">- $ ' +
            this.pricingService.roundDecimals(emailDiscount,2) +
            '</b></td>';
          body += '</tr>';
        }

        body += '<tr>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b>Taxes & Fees</b></td>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">$ ' +
          this.pricingService.roundDecimals(this.rental.taxes,2) +
          '</b></td>';
        body += '</tr>';

        body += '<tr>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b></b></td>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;"><b>Total</b></td>';
        body +=
          '<td style ="padding-right: 20px; border: 1px solid #ddd; text-align: end;">$ ' +
          this.pricingService.roundDecimals(this.rental.cost,2) +
          '</b></td>';
        body += '</tr>';

        body += '<br>';
        body += '<br>';

        body += '<tr style="border-spacing: 0 1em;">';
        body +=
          '<td colspan="4" style="text-align:center; font-weight: bold; font-size: 15px"><a href="' +
          waiverUrl +
          '"> You can review and sign our waiver before coming into pickup your rental </a> </td>';
        body += '</tr>';
        body += '<tr>';
        body +=
          '<td colspan="4" style="text-align:center"><b> ' +
          this.companyObj.companyName +
          ' hours are between ' +
          this.companyObj.schedule.startTime +
          ' - ' +
          this.companyObj.schedule.endTime +
          ' ' +
          this.companyObj.schedule.dayStart +
          ' - ' +
          this.companyObj.schedule.dayEnd +
          '</b></td>';
        body += '</tr>';

        this.companyObj;

        body += '</tbody>';
        body += '</table>';
        body += '<br>';

        // call pdf function

        const emailDoc = {
          emailTo: this.rental.userInfo.email,
          emailSubject: `Rental #${this.rental.rentalNumber}, Booking Confirmation`,
          emailBody: body,
          companyID: this.rental.companyID,
          date: new Date(),
          rentalID: this.rental.id,
        };
        this._afs.collection('emails').add(emailDoc);
      });
  }
  async openCharges() {
    this._rentalService.companyID = this.rental.companyID;
    this.productLocation = await this._rentalService.getDefaultLocation(
      this.companyObj.defaultLocation
    );

    if (this.companyObj.isSmartWaiver) {
      await this._rentalService
        .getSmartWaiver(this.rental.id)
        .subscribe((waiver) => {
          if (waiver.length > 0) {
            this.smartwaiversigned = true;
            this.smartwaiverdata = waiver;
          } else {
            this.smartwaiversigned = false;
          }
        });
    }
    this.charges = [];
    const main = await this._rentalService.getMainPayment(this.rental.id);
    const charges = await this._rentalService.getCharges(this.rental.id);
    const deposits = await this._rentalService.getDeposit(this.rental.id);
    var refunds = [];
    await this._rentalService.getRefunds(this.rental.id).then((refund) => {
      refund.forEach((refund) => {
        var found = false;
        charges.forEach((charge, index) => {
          if (charge.id == refund.payment_intent) {
            found = true;
            charges[index].totalrefunded
              ? (charges[index].totalrefunded += refund.amount)
              : (charges[index].totalrefunded = refund.amount);

            if (charges[index].refunds) {
              charges[index].refunds.push(refund);
            } else {
              charges[index].refunds = [refund];
            }
          }
        });
        deposits.forEach((deposit, index) => {
          if (deposit.id == refund.payment_intent) {
            found = true;
            deposits[index].totalrefunded
              ? (deposits[index].totalrefunded += refund.amount)
              : (deposits[index].totalrefunded = refund.amount);

            if (deposits[index].refunds) {
              deposits[index].refunds.push(refund);
            } else {
              deposits[index].refunds = [refund];
            }
          }
        });
        main.forEach((mainp, index) => {
          if (mainp.id == refund.payment_intent) {
            found = true;
            main[index].totalrefunded
              ? (main[index].totalrefunded += refund.amount)
              : (main[index].totalrefunded = refund.amount);

            if (main[index].refunds) {
              main[index].refunds.push(refund);
            } else {
              main[index].refunds = [refund];
            }
          }
        });
        if (!found) {
          refunds.push(refund);
        }
      });
    });
    this.charges.push(...main);
    this.charges.push(...charges);
    this.charges.push(...deposits);
    this.charges.push(...refunds);
    this.bookingscomponent.asyncTotals(this.rental);
  }
  convertDate(timestamp) {
    return moment.unix(timestamp).format('LLL');
  }

  toSaveChange(e: any) {
    if (Number(e.target.value) > 100) {
      (Swal.getPopup().querySelector('#toSave') as HTMLInputElement).value =
        String(100);
    }
    const percent = Number(e.target.value);
    this.toSave = this.pricingService.roundDecimals(((percent * this.chargeAmount) / 100),2);
    this.amount = this.pricingService.roundDecimals((this.chargeAmount - this.toSave),2);
    this.percent = 100 - Number(e.target.value);
    (Swal.getPopup().querySelector('#amount') as HTMLInputElement).value =
      String(this.amount);
  }

  amountChange(e: any) {
    const amount = Number(e.target.value);
    this.percent = this.pricingService.roundDecimals(((100 * amount) / this.chargeAmount),2);
    this.toSave = this.pricingService.roundDecimals((this.chargeAmount - amount),2);
    const percent = this.pricingService.roundDecimals((100 - Number(this.percent)),2);
    (Swal.getPopup().querySelector('#toSave') as HTMLInputElement).value =
      String(percent);
  }

  async refundAmount(charge) {
    this.hideMyAlert = false;
    this._rentalService.companyID = this.rental.companyID;
    var total = charge.amount;
    if (charge.refunds) {
      charge.refunds.forEach((refund) => {
        total -= refund.amount;
      });
    }

    this.chargeAmount = this.pricingService.roundDecimals((charge.amount / 100),2);

    // Swal.fire({
    //   title: 'Type the Amount to Refund',
    //   input: 'text',
    //   inputAttributes: {
    //     autocapitalize: 'off',
    //   },
    //   showCancelButton: true,
    //   confirmButtonText: 'Confirm Amount',
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   icon: 'question',
    //   inputValidator: (value) => {
    //     if (!value) {
    //       return 'You need to write an Amount';
    //     } else if (Number(value) > Number(total / 100)) {
    //       return 'Amount exceeds total';
    //     }
    //     return null;
    //   },
    // }).then((result) => {
    //   if (result.isConfirmed) {

    Swal.fire({
      allowOutsideClick: false,
      html: this.myAlert.nativeElement,
      confirmButtonText: 'Confirm Amount',
      showCancelButton: true,
      focusConfirm: false,
      didOpen: () => {
        (Swal.getPopup().querySelector('#toSave') as HTMLInputElement).focus();
        (Swal.getPopup().querySelector('#toSave') as HTMLInputElement).value =
          '';
        (Swal.getPopup().querySelector('#amount') as HTMLInputElement).value =
          '';
        (
          Swal.getPopup().querySelector('#toSave') as HTMLInputElement
        ).addEventListener('input', function () {
          var val = this.value;
          this.value = val.replace(/[^0-9,.]/g, '').replace(/,/g, '.');
        });
        (
          Swal.getPopup().querySelector('#amount') as HTMLInputElement
        ).addEventListener('input', function () {
          var val = this.value;
          this.value = val.replace(/[^0-9,.]/g, '').replace(/,/g, '.');
        });
      },
      preConfirm: () => {
        const amount = Number(
          (Swal.getPopup().querySelector('#amount') as HTMLInputElement).value
        );
        if (!amount) {
          Swal.showValidationMessage(`The amount must be greater than 0.`);
        }
        return null;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const amount = Number(
          (Swal.getPopup().querySelector('#amount') as HTMLInputElement).value
        );
        Swal.fire({
          title: 'Are you sure you want to refund this amount',
          html: 'You will not be able to undo this action',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          icon: 'question',
        }).then((result2) => {
          if (result2.isConfirmed) {
            Swal.fire({
              title: 'Loading...',
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
            });
            Swal.showLoading();
            let dataRefund = {
              isTesting: this._rentalService.isTesting,
              stripeID: this.dataPI.stripeID,
              paymentID: charge.id,
              amount: this.pricingService.roundDecimals((amount * 100),2),
              description: 'Partial Refund',
              companyID: this.rental.companyID,
              email: this.rental.userInfo.email,
              id: this.rental.id,
            };
            this.afFun
              .httpsCallable('refundPaymentAmount')(dataRefund)
              .subscribe(async (resultrefund) => {
                Swal.close();
                if (resultrefund.raw?.code == 'charge_already_refunded') {
                  Swal.fire({
                    title: 'Booking Refunded',
                    text: 'The payment has already been refunded before',
                    icon: 'error',
                  });
                } else {
                  resultrefund['type'] = 'refund';
                  resultrefund['rentalID'] = this.rental.id;
                  resultrefund.metadata['type'] = 'refund';
                  resultrefund.metadata['rentalID'] = this.rental.id;
                  resultrefund.metadata['userName'] =
                    this._currentUser.currentUser.firstName +
                    ' ' +
                    this._currentUser.currentUser.lastName;
                  resultrefund.metadata['userID'] =
                    this._currentUser.currentUser.id;
                  resultrefund.metadata['rentalID'] = this.rental.id;
                  resultrefund.metadata['title'] = 'Partial Refund';
                  resultrefund.metadata['companyID'] = this.rental.companyID;
                  await this._rentalService.addStripeTransaction(resultrefund);
                  await this._logService.addRentalLog(
                    this.rental.id,
                    'Refund created, amount: ' +
                    this.pricingService.roundDecimals((dataRefund.amount / 100),2)
                  );
                  let amountPending = this.rental.amountPendingToRefund > 0 ? this.rental.amountPendingToRefund - amount : 0;
                  await this._rentalService.updateRental({ amountPending: amountPending }, this.rental.id);
                  this.openCharges();
                  this.percent = 0;
                  this.toSave = 0;
                  Swal.fire({
                    title: 'Refund Successful',
                    icon: 'success',
                  });
                }
              });
          } else {
            Swal.fire({
              title: 'Refund Canceled',
              icon: 'info',
            });
          }
        });
      } else {
        this.percent = 0;
        this.toSave = 0;
        (Swal.getPopup().querySelector('#toSave') as HTMLInputElement).value =
          String(0);
        (Swal.getPopup().querySelector('#amount') as HTMLInputElement).value =
          String(0);
      }
    });
  }
  refundPayment(charge) {
    this._rentalService.companyID = this.rental.companyID;
    Swal.fire({
      title: 'Refund Payment',
      html: 'Are you sure you want to refund this payment?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading();
        var dat = {
          stripeID: this.dataPI.stripeID,
          id: charge.id,
        };
        var rental = {
          stripeID: this.dataPI.stripeID,
          companyID: this._currentUser.currentUser.companyId,
          description: 'Full Refund',
          email: this.rental.userInfo.email,
          isTesting: this._rentalService.isTesting,
          paymentID: this.rental.paymentID,
          amount: this.pricingService.roundDecimals((this.refundForm.value.amount * 100),2),
          id: this.rental.id,
        };
        this.afFun
          .httpsCallable('refundPayment')({
            data: dat,
            rental: rental,
            isTesting: this._rentalService.isTesting,
          })
          .subscribe((result) => {
            this.openCharges();
            Swal.close();
            result['type'] = 'refund';
            result['rentalID'] = this.rental.id;
            result.metadata['type'] = 'refund';
            result.metadata['rentalID'] = this.rental.id;
            result.metadata['userName'] =
              this._currentUser.currentUser.firstName +
              ' ' +
              this._currentUser.currentUser.lastName;
            result.metadata['userID'] = this._currentUser.currentUser.id;
            result.metadata['title'] = 'Full Refund';
            result.metadata['companyID'] = this.rental.companyID;
            this._rentalService.addStripeTransaction(result);
            this._logService.addRentalLog(
              this.rental.id,
              'Refund created, amount: ' +
              this.pricingService.roundDecimals((charge.amount / 100),2)
            );
            if (result) {
              this._rentalService.updateRental(this.rental, this.rental.id);
              Swal.fire({
                title: 'The Payment has been refunded',
                icon: 'success',
              }).then((result) => {
                if (result.isConfirmed) {
                }
              });
            } else {
              Swal.fire('Error', 'Something went wrong', 'error');
            }
          });
      }
    });
  }
  displayRefund(charge, j) {
    if (charge.refunds) {
      var id = 'accordion' + j.toString();
      var iddisplay = 'display' + j.toString();
      var idhide = 'hide' + j.toString();
      const div = document.getElementById(id) as HTMLElement;
      const display = document.getElementById(iddisplay) as HTMLElement;
      const hide = document.getElementById(idhide) as HTMLElement;
      div.style.display = 'initial';
      display.style.display = 'none';
      hide.style.display = 'initial';
    }
  }
  hideRefund(charge, j) {
    if (charge.refunds) {
      var id = 'accordion' + j.toString();
      var iddisplay = 'display' + j.toString();
      var idhide = 'hide' + j.toString();
      const div = document.getElementById(id) as HTMLElement;
      const display = document.getElementById(iddisplay) as HTMLElement;
      const hide = document.getElementById(idhide) as HTMLElement;
      div.style.display = 'none';
      display.style.display = 'initial';
      hide.style.display = 'none';
    }
  }
  cancelPayment(deposit) {
    Swal.fire({
      title: 'Cancel Deposit',
      html: 'Are you sure yo want to cancel this deposit?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading();
        this.rental.stripeID = this.companyObj.stripeID;
        this.afFun
          .httpsCallable('cancelPayment')({
            paymentID: deposit.id,
            stripeID: this.companyObj.isTesting ? this.companyObj.stripeIDTest : this.companyObj.stripeID,
            isTesting: this._rentalService.isTesting,
            rental: this.rental,
          })
          .subscribe(async (resultcancel) => {
            if (resultcancel.error) {
              Swal.close();
              Swal.fire({
                title: 'Error',
                html: 'Please contact the administrator to see the problem. ' + resultcancel.error,
                icon: 'error',
              });
            } else {
              resultcancel.cancel['type'] = 'deposit';
              resultcancel.cancel['rentalID'] = this.rental.id;
              resultcancel.cancel.metadata['type'] = 'deposit';
              resultcancel.cancel.metadata['rentalID'] = this.rental.id;
              resultcancel.cancel.metadata['title'] = 'Security Deposit';
              resultcancel.cancel.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              resultcancel.cancel.metadata['userID'] =
                this._currentUser.currentUser.id;
              resultcancel.cancel.metadata['companyID'] = this.rental.companyID;
              this._rentalService.addStripeTransaction(resultcancel.cancel);
              this._logService.addRentalLog(
                this.rental.id,
                'Security Deposit Cancel, amount: ' +
                this.pricingService.roundDecimals((deposit.amount / 100),2)
              );
              Swal.close();
              Swal.fire({
                title: 'Deposit Canceled',
                html: 'The security Deposit has been canceled',
                icon: 'success',
              });
              this.openCharges();
            }
          });
      }
    });
  }
  confirmPayment(deposit) {
    Swal.fire({
      title: 'Confirm Deposit',
      html: 'Are you sure yo want to confirm this deposit?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
        });
        Swal.showLoading();
        this.rental.stripeID = this.companyObj.stripeID;
        this.afFun
          .httpsCallable('capturePayment')({
            paymentID: deposit.id,
            isTesting: this._rentalService.isTesting,
            rental: this.rental,
          })
          .subscribe(async (resultconfirm) => {
            if (resultconfirm.error) {
              Swal.close();
              Swal.fire({
                title: 'Error',
                html: 'Please contact the administrator to see the problem. ' + resultconfirm.error,
                icon: 'error',
              });
            } else {
              resultconfirm.capture['type'] = 'deposit';
              resultconfirm.capture['rentalID'] = this.rental.id;
              resultconfirm.capture.metadata['type'] = 'deposit';
              resultconfirm.capture.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              resultconfirm.capture.metadata['userID'] =
                this._currentUser.currentUser.id;
              resultconfirm.capture.metadata['rentalID'] = this.rental.id;
              resultconfirm.capture.metadata['title'] = 'Security Deposit';
              resultconfirm.capture.metadata['companyID'] = this.rental.companyID;
              this._rentalService.addStripeTransaction(resultconfirm.capture);
              this._logService.addRentalLog(
                this.rental.id,
                'Security Deposit Confirmed, amount: ' +
                this.pricingService.roundDecimals((deposit.amount / 100),2)
              );
              Swal.close();
              Swal.fire({
                title: 'Deposit Confirmed',
                html: 'The security Deposit has been confirmed',
                icon: 'success',
              });
              this.openCharges();
            }
          });
      }
    });
  }
  convertStringDate(date) {
    return moment(new Date(date)).format('LLL');
  }

  openModalShopComment(comments: string) {

    this.shopSaveButton = false;
    const textFromFirebase = comments == undefined ? '' : comments.replace(/>>>>/g, '\n');

    this.myForm.get('shopComments').setValue(textFromFirebase);

    $('#editShopComments').modal({ backdrop: 'static', keyboard: false });
    $('#editShopComments').modal('show');
  }

  setInputFocus(): void {
    setTimeout(() => {
      document.getElementById('shopComments').focus();
    }, 500);
  }

  async saveChanges(rentalID: string) {
    this.shopSaveButton = true;

    Swal.fire({
      title: 'Loading...',
      timer: 5000,
    });
    Swal.showLoading();

    if (this.myForm.get('shopComments').value === undefined) {
      $('#editShopComments').modal('hide');
      Swal.fire({
        icon: 'success',
        iconColor: '#198754',
        text: 'Shop comments saved successfully',
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    const textareaElement: HTMLTextAreaElement = this.myTextarea.nativeElement;
    const textOriginal = textareaElement.value;
    const textToSave = textOriginal.replace(/\n/g, '>>>>');

    const shopComment = {
      shopComments: textToSave,
    };

    const updateShopComments: any =
      await this._rentalService.updateShopComments(rentalID, shopComment);

    if (updateShopComments.success) {
      $('#editShopComments').modal('hide');
      Swal.fire({
        icon: 'success',
        iconColor: '#198754',
        text: 'Shop comments saved successfully',
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
  @Input('showCards') showcards: boolean = false;
  hideAllPayment() {
    this.showcards = false;
  }
  resetAllCards() {
    this.submittedpayment = false;
    this.typecharge = '';
    this.viewAllPayment();
  }
  async viewAllPayment() {
    this.showcards = true;
    this.selectedNew = false;
    this.selectedPayment = false;
    this.paymentsmethods = false;
    this.loadingcards = true;
    this.legendLoad = 'Getting all Payment Methods';
    var payments: any;
    var paymentsStripe: any = [];
    //$('#cardsModal').modal('show');
    await this._rentalService
      .getAllPaymentsSuccedeed(this.rental.id)
      .then((methods) => {
        payments = methods;
      });
    let promises = [];
    payments.forEach(async (payment_method, index) => {
      if (payment_method.type != 'refund') {
        let promise = new Promise((resolve) => {
          this.afFun
            .httpsCallable('retrievePayment')({
              paymentID: payment_method.id,
              isTesting: this.companyObj.isTesting,
              stripeID: this.companyObj.stripeID,
            })
            .pipe(take(1))
            .subscribe((payment) => {
              paymentsStripe.push(payment);
              resolve(true);
            });
        });
        promises.push(promise);
      }
    });
    Promise.all(promises).then((_) => {
      setTimeout(() => {
        this.legendLoad = 'Processing ' + paymentsStripe.length + ' payments';
        payments = paymentsStripe.filter(
          (v, i, a) =>
            a.findIndex((v2) => v2.payment_method === v.payment_method) === i
        ); //Filter all objects removing repeated payment_methods
        this.legendLoad =
          'Getting 0 of ' + payments.length + ' Payment Methods';
        this.paymentsmethods = payments;
        this.loadingcards = false;
      }, 1500);
    });
  }
  selectPayment(method, position, newpayment?: boolean) {
    if (newpayment) {
      if (this.selectedNew) {
        this.selectedNew = false;
        this.selectedPayment = false;
        this.paymentsmethods.forEach((payment, index) => {
          this.paymentsmethods[index].isSelected = false;
        });
        this.selectedMethod = {};
      } else {
        this.selectedPayment = true;
        this.selectedNew = true;
        this.paymentsmethods.forEach((payment, index) => {
          this.paymentsmethods[index].isSelected = false;
        });
        this.selectedMethod = {
          new: true,
        };
      }
    } else {
      this.selectedNew = false;
      if (this.paymentsmethods[position].isSelected) {
        this.paymentsmethods.forEach((payment, index) => {
          this.paymentsmethods[index].isSelected = false;
        });
        this.selectedPayment = false;
      } else {
        this.selectedPayment = true;
        this.paymentsmethods.forEach((payment, index) => {
          this.paymentsmethods[index].isSelected = false;
        });
        this.paymentsmethods[position].isSelected = true;
      }
      this.selectedMethod = method;
    }
  }

  changeAllPrice(type) {
    if (type === 'taxes') {
      this.applytaxes ? (this.applytaxes = false) : (this.applytaxes = true);
    }
    if (this.applytaxes) {
      this.amounttocharge = 
      this.pricingService.roundDecimals((
          Number(this.chargeamount) +
          Number(this.chargeamount) * (this.companyTax / 100) +
          Number(this.chargeamount) * (this.fleetTax / 100)
        ),2);
      this.totalwithtaxes =
        'Total with Taxes and Fees:';
      this.totalWithTaxesAmount = this.amounttocharge
    } else {
      this.amounttocharge = Number(this.chargeamount);
      this.totalwithtaxes =
        'Total without Taxes and Fees:';
      this.totalWithTaxesAmount = this.amounttocharge;
    }
    //Format with two decimals
    const rentalCost = this.pricingService.roundDecimals(this.rental.cost,2);
    const rentalTotalCompletePaid = this.pricingService.roundDecimals(this.rentalTotalCompletePaid,2);
    const totalWithTaxesAmount = this.pricingService.roundDecimals(this.totalWithTaxesAmount,2);

    this.pendingAmount = this.pricingService.roundDecimals((rentalCost - (rentalTotalCompletePaid + totalWithTaxesAmount)),2);
  }

  displayCalculator() {
    this.peoplenumber = 1;
    this.pendingtotal = this.rental.amountPending;
    let totaltaxes =
      this.pendingtotal +
      this.pendingtotal * (this.companyTax / 100) +
      this.pendingtotal * (this.fleetTax / 100);
    if (
      this.pendingtotal > 0 &&
      this.pendingtotal.toString() != '' &&
      this.peoplenumber > 0 &&
      this.peoplenumber.toString() != ''
    ) {
      this.totalonewithout = this.pricingService.roundDecimals(
        (this.pendingtotal / this.peoplenumber),2);
      this.totalonewith = this.pricingService.roundDecimals((totaltaxes / this.peoplenumber),2);
      this.totalaftertax =
        'Total payment after taxes: $' + this.pricingService.roundDecimals(totaltaxes,2);
    } else {
      this.totalonewithout = 0;
      this.totalonewith = 0;
      if (totaltaxes == 0) {
        this.totalaftertax = 'Total payment after taxes: $0';
      }
    }
    this.hideCalculatorModal = false;
    Swal.fire({
      title: 'Split Payment Calculator',
      allowOutsideClick: false,
      html: this.calculatorModal.nativeElement,
      showCancelButton: true,
      showConfirmButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      cancelButtonText: 'Ok',
    }).then(() => {
      this.hideCalculatorModal = true;
    });
  }

  calculateSplit() {
    let totaltaxes =
      this.pendingtotal +
      this.pendingtotal * (this.companyTax / 100) +
      this.pendingtotal * (this.fleetTax / 100);
    if (
      this.pendingtotal > 0 &&
      this.pendingtotal.toString() != '' &&
      this.peoplenumber > 0 &&
      this.peoplenumber.toString() != ''
    ) {
      this.totalonewithout = this.pricingService.roundDecimals((this.pendingtotal / this.peoplenumber),2);
      this.totalonewith = this.pricingService.roundDecimals((totaltaxes / this.peoplenumber),2);
      this.totalaftertax =
        'Total payment after taxes: $' + this.pricingService.roundDecimals(totaltaxes,2);
    } else {
      this.totalonewithout = 0;
      this.totalonewith = 0;
      if (totaltaxes == 0) {
        this.totalaftertax = 'Total payment after taxes: $0';
      }
    }
  }

  CommentsConversion(Comments: string) {
    const textCommets = Comments
      .replace(/>>>>/g, '\n');

    return textCommets;
  }

  async miniCartUpdate(e) {

    if (e.databaseUpdate) {
      this.isQuickBookChange.emit(); // Make sure all compoents hold the same value for quick book
      this._rentalService.getRentalByIDPromise(e.rentalID).then(async (res: Rental) => {
        this.rental = res;

        this.determineItemsInCartObjBool(this.rental);

        this.rental = await this.inventoryPageService.createAddonsDisplayArrayByProduct(this.rental);
        let newLogString = this.inventoryPageService.turnAddonDisplayIntoLogString(this.rental);

        await this._logService.addRentalLog(this.rental.id, newLogString); // dates are converted to JS dates so firestore can create timestamps

        if (this.rental.amountPendingToRefund && Number(this.rental.amountPendingToRefund) > 0) {
          let chargesTab = document.getElementById('charges-tab')
          chargesTab.click();
        }
        else if (this.rental.amountPending && Number(this.rental.amountPending) > 0) {
          this.bookingscomponent.rentalClick(this.rental).then(async _ => {
            this.viewAllPayment();
          })
        }
        else {
          // need to refresh the variable at the top even if there is no charge/refund
          this.bookingscomponent.asyncTotals(this.rental)
        }
      })
    }
  }

  isObjectEmpty(object) {
    return Object.keys(object).length === 0;
  }

  rentalModifiedUpdate(e) {
    this.rentalBeingModified = e;
  }

  rentalTotalsUpdate(rental) {
    this.rentalCostUpdate.emit(rental)
  }

  private checkIfProductWidgetsAreAvailable(productWidgetIDArray: string[], dayStart: DateTime, dayEnd: DateTime, swappableItems: SwappableProducts[], slotType: string): boolean {
    if (swappableItems) {
      if (swappableItems.length > 0) {
        swappableItems.forEach(item => {
            const index = productWidgetIDArray.indexOf(item['originalID']);
            productWidgetIDArray[index] = item['newID'];
        })
      }
    }

    productWidgetIDArray.forEach(prodID => {
      if (this.availability.checkProductRentalAvailability(this.productsMap[prodID].productGroupID, prodID, dayStart, dayEnd, slotType)) {
        productWidgetIDArray.splice(productWidgetIDArray.indexOf(prodID), 1);
      }
    })

    return productWidgetIDArray.length > 0 ? false : true
  }

  copyPaymentLink() {
    const templateID = this.selectedTemplate ? this.selectedTemplate : this.templates[0].id;
    const paymentLink = environment.app.appDomainName + `/book/` + templateID + `/cart` + `?rental=${this.rental.id}`;
    this.cartService.copyTextToClipboard(paymentLink);
  }

  protected changeRentalTime(): void {
    this.dateRangeComponent.provideParamsOnInputChange(DateRangeInputType.DatePicker); // Uses DatePicker over manual because we're reserving input type manual for other reasons in this component
  }

  ngOnDestroy() {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
  }
}
