<form class="needs-validations" #groupf="ngForm" [formGroup]="configForm" (ngSubmit)="onSubmit()">

  <!-- active -->
  <div class="row">
    <div class="mb-1">
      <span>View Type Preference</span>
      <span class="text-danger">*</span> <!-- Required Symbl -->
    </div>
    <div class="form-group-sm mb-2 input-group-sm">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="viewTypePreference" [value]="'catalog'"
          id="viewTypePreference_catalog">
        <label class="form-check-label" for="viewTypePreference_catalog">Catalog View</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="viewTypePreference" [value]="'date'"
          id="viewTypePreference_date">
        <label class="form-check-label" for="viewTypePreference_date">Date View</label>
      </div>
      <div class="form-text">
        <span>Choose the default view type for the booking flow. This value determines the view a user will initially
          see
          when navigating to your booking flow from the home route</span>
      </div>
    </div>
  </div>

  <div class="row mt-1">
    <div class="mt-1 mb-1">
      <span>Show Date Picker Automatically</span>
    </div>
    <div class="form-group-sm mb-2 input-group-sm">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" formControlName="openDateRangeOnInit" id="openDateRangeOnInit">
        <label class="form-check-label" for="openDateRangeOnInit">Open Date Picker on Start</label>
      </div>
      <div class="form-text">
        <span>
          Enable this option to automatically open the date picker when the booking process begins.
        </span>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="container-xxl">
    <div class="row justify-content-center mt-2">
      <div class="text-center">
        <!-- || awaitingDB -->
        <button type="submit" class="btn btn-success" [disabled]="configForm.invalid">
          <span>Save Changes</span>
          <!-- <span *ngIf="awaitingDB" class="spinner-grow spinner-grow-sm ml-1" role="active" aria-hidden="true"></span> -->
        </button>
      </div>
    </div>
  </div>
</form>