<div class="container mt-4">
  <div class="row">
    <!-- Select Period Dropdown -->
    <div class="col-12 col-md-6 mb-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Select a Period</mat-label>
        <mat-select [(value)]="selectedPeriod" (selectionChange)="setPeriod($event.value)">
          <mat-option *ngFor="let period of periodOptions" [value]="period.value">
            {{ period.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Date Range Picker (Only visible when 'custom' is selected) -->
    <div class="col-12 col-md-6 mb-3" *ngIf="selectedPeriod === 'custom'">
      <mat-form-field appearance="outline" class="w-100" (click)="rangePicker.open()">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate placeholder="Start date" #startDateInput class="form-control" readonly>
          <input matEndDate placeholder="End date" #endDateInput class="form-control" readonly>
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary"
                    (click)="applySelection(startDateInput, endDateInput, rangePicker)"
                    matDateRangePickerApply>Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>


  <!-- Selected Date Range -->
  <div *ngIf="selectedRange.start && selectedRange.end" class="date-summary">
    <p>Selected range: {{ selectedRange.start.toFormat('DD') }} - {{ selectedRange.end.toFormat('DD') }}</p>
  </div>
</div>
