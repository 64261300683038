import { Component, Input, OnInit, Output, EventEmitter, SimpleChange, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Interfaces
import { BookingTemplate, defaultValues } from 'src/app/v2/models/booking-flow.model';

// Services
import { BookingService } from 'src/app/services/booking.service';

// Libraries
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bookingFlowConfigModal',
  templateUrl: './bookingFlowConfigModal.component.html',
  styleUrls: ['./bookingFlowConfigModal.component.css']
})
export class BookingFlowConfigModalComponent implements OnInit, OnChanges {

  @Input() public template: BookingTemplate;
  @Output() public bookingFlowConfigFormSubmission = new EventEmitter<string>();
  public configForm: FormGroup;

  constructor(private bookingService: BookingService) { }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.configForm = new FormGroup({
      viewTypePreference: new FormControl("", [Validators.required]),
      openDateRangeOnInit: new FormControl(false)
    })
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.template) { // Set the form with the values from the booking template upon being set from parent component
      this.setFormWithValues(simpleChanges.template.currentValue);
    }
  }


  public setFormWithValues(bookingTemplate: BookingTemplate): void {
    if (!this.configForm?.controls) {
      return
    }

    // If the booking template doesn't have a template, set catalog as the default view type viewTypePreference
    if (bookingTemplate?.viewTypePreference) {
      this.configForm.controls['viewTypePreference'].setValue(bookingTemplate.viewTypePreference); // set to db value
    } else {
      this.configForm.controls['viewTypePreference'].setValue('catalog'); // set default
    }

    const openDateRangeOnInitValue =
    bookingTemplate?.config?.openDateRangeOnInit !== undefined
      ? bookingTemplate.config.openDateRangeOnInit
      : false;

    // Now we set the form control accordingly
    this.configForm.controls['openDateRangeOnInit'].setValue(openDateRangeOnInitValue);
  }

public onSubmit(): void {
  const updateObj: Partial<BookingTemplate> = {}; // holds all the properties that need to be updated

  const controlNames = Object.keys(this.configForm.controls);

  for (const controlName of controlNames) {
    const newValue = this.configForm.get(controlName)?.value;

    // Check if it is the specific 'openDateRangeOnInit' control
    if (controlName === 'openDateRangeOnInit') {
      const currentValue = this.template.config?.openDateRangeOnInit;

      // Update only if the value has changed
      if (newValue !== currentValue) {
        updateObj.config = {
          ...this.template.config,
          openDateRangeOnInit: newValue
        };
      }
    } else {
      // For the rest of the controls, validate the existing value in the template
      const existingValue = this.template[controlName];

      // If the field doesn't exist in the template, check if there is a default value
      if (existingValue === undefined) {
        if (controlName in defaultValues) {
          updateObj[controlName] = defaultValues[controlName];
        } else {
          // If not in defaultValues, the field is not included in the template interface
          throw new Error(`Field '${controlName}' not found in the template interface.`);
        }
      }

      // Update only if the value has changed
      if (newValue !== existingValue) {
        updateObj[controlName] = newValue;
      }
    }
  }

  // Call the service to update the booking template in the database
  this.bookingService.updateTemplateFields(this.template.id, updateObj)
    .then(() => {
      this.deliverToast('Booking configurations updated successfully!', 'success');
      this.bookingFlowConfigFormSubmission.emit('success');
    })
    .catch((error) => {
      // Handle errors during the update process
      this.deliverToast(`Error updating configurations: ${error.message}`, 'danger');
    });
}

  private deliverToast(title: string, icon: any): void {
    Swal.fire({
      toast: true,
      position: 'bottom-right',
      title: title,
      icon: icon,
      showConfirmButton: false,
      timer: 1500
    })
  }
}
