<div class="container-fluid">
  <!-- TITLE -->
  <div class="row justify-content-center mt-2">
    <div class="col-md-6 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Affiliate Report</h1>
    </div>
    <!-- DATE RANGE PICKER -->
    <div class="col-md-6">
      <div class="d-flex flex-column flex-sm-row align-items-center">
        <div class="col-12">
          <span class="p-float-label">
            <app-period-selection-daypicker (periodSelected)="onPeriodSelected($event)"></app-period-selection-daypicker>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-4 d-flex justify-content-center">
    <div class="row justify-content-center w-100">
      <!-- Earnings Card -->
      <div class="col-md-5">
        <div class="stats-card">
          <div class="stats-content">
            <div class="stats-text">
              <span class="stats-title">EARNINGS</span>
              <h2 class="stats-value">
                {{ totalEarningsHeader | currency:'USD':'symbol':'1.2-2' }}
              </h2>
            </div>
            <i class="fas fa-dollar-sign stats-icon earnings-icon"></i>
          </div>
        </div>
      </div>

      <!-- Total Rentals Card -->
      <div class="col-md-5">
        <div class="stats-card">
          <div class="stats-content">
            <div class="stats-text">
              <span class="stats-title">TOTAL RENTALS</span>
              <h2 class="stats-value">
                {{ totalRentalsHeader | number:'1.0-0' }}
              </h2>
            </div>
            <i class="fas fa-shopping-cart stats-icon rentals-icon"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container mt-4">
      <!-- Header container with only the Download CSV button on the right -->
      <div class="header">
        <button mat-raised-button color="primary" (click)="downloadCSV()">Download CSV</button>
      </div>

      <!-- Data Table with Material Design -->
      <div class="table-container table-responsive">
        <table mat-table [dataSource]="dataSource">

          <!-- Affiliate Name Column -->
          <ng-container matColumnDef="affiliateName">
            <th mat-header-cell *matHeaderCellDef>Affiliate Name</th>
            <td mat-cell *matCellDef="let element" data-label="Affiliate Name">{{ element.affiliateName }}</td>
          </ng-container>

          <!-- Earnings Column -->
          <ng-container matColumnDef="totalEarnings">
            <th mat-header-cell *matHeaderCellDef class="text-right">Total Earnings</th>
            <td mat-cell *matCellDef="let element" data-label="Total Earnings" class="text-right">
              {{ element.earnings | currency:'USD':'symbol':'1.2-2' }}
            </td>
          </ng-container>

          <!-- Total Booking Costs Column -->
          <ng-container matColumnDef="totalBookingCosts">
            <th mat-header-cell *matHeaderCellDef class="text-right">Total Booking Costs</th>
            <td mat-cell *matCellDef="let element" data-label="Total Booking Costs" class="text-right">
              {{ element.totalBookingCosts | currency:'USD':'symbol':'1.2-2' }}
            </td>
          </ng-container>

          <!-- Number of Rentals Column -->
          <ng-container matColumnDef="numberOfRentals">
            <th mat-header-cell *matHeaderCellDef class="text-right">Number of Rentals</th>
            <td mat-cell *matCellDef="let element" data-label="Number of Rentals" class="text-right">
              {{ element.numberOfRentals }}
            </td>
          </ng-container>

          <!-- Number of Clicks Column -->
          <ng-container matColumnDef="numberOfClicks">
            <th mat-header-cell *matHeaderCellDef class="text-right">Number of Clicks</th>
            <td mat-cell *matCellDef="let element" data-label="Number of Clicks" class="text-right">
              {{ element.numberOfClicks }}
            </td>
          </ng-container>

          <!-- Conversion Ratio Column -->
          <ng-container matColumnDef="conversionRatio">
            <th mat-header-cell *matHeaderCellDef class="text-right">Conversion Ratio (%)</th>
            <td mat-cell *matCellDef="let element" data-label="Conversion Ratio (%)" class="text-right">
              {{ element.conversionRatio | number:'1.2-2' }}%
            </td>
          </ng-container>

          <!-- Table Header and Rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[100]" showFirstLastButtons></mat-paginator>
      </div>

    </div>
  </div>
</div>
